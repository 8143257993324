/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import axios, { AxiosError, AxiosResponse } from "axios";
// import { toast } from "react-toastify";

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: unknown) => {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const expectedError =
        axiosError.response &&
        axiosError.response.status >= 400 &&
        axiosError.response.status < 500;

      // Check if the error is an Axios cancel error
      if (axios.isCancel(error)) {
        console.log("Axios Interceptor: Request cancelled", axiosError.message);
        return Promise.reject(error);
      }

      // Check if the error is a request aborted error. In firefox, this happens
      // on a page refresh
      if (axiosError.code === "ECONNABORTED") {
        console.log("Axios Interceptor: Request aborted");
        if (axiosError.message.includes("timeout")) {
          // This is an attempt to flag real timeout econnaborted errors.
          console.log(
            "Axios Interceptor: Request timed out",
            axiosError.message,
          );
        }
        return Promise.reject(error); // Consider it as an expected error
      }

      if (!expectedError) {
        console.log("Axios Interceptor: Unexpected error: ", error);
        // toast.error("An unexpected error occurred");
      }
    } else {
      console.log("Non-Axios error occurred: ", error);
      return Promise.reject(error);
    }
  },
);

function setJwt(jwt: string) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

const httpExport = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
export default httpExport;
