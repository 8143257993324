import React, { useState } from "react";
import { Outlet, useLocation, useLoaderData, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Grid,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getProperties } from "../services/propertyService";
import { IProperty } from "@farlosoftware/common-types";

type LoaderDataType = {
  properties: IProperty[];
};

export async function loader(): Promise<LoaderDataType> {
  const result = await getProperties();
  if (!result.success || !result.value) {
    console.log("Failed to get properties");
    return { properties: [] };
  }
  const properties: IProperty[] = result.value;
  return { properties: properties };
}

export default function Properties() {
  const location = useLocation();
  const data = useLoaderData() as LoaderDataType;
  const properties = data.properties;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const pathSegmentsToRemove = ["/edit", "/taxes", "/ledger"];
  let pathname = location.pathname;

  for (const segment of pathSegmentsToRemove) {
    if (pathname.endsWith(segment)) {
      pathname = pathname.slice(0, -segment.length);
      break; // Exit loop after removing a matching segment
    }
  }

  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Outlet />
    </Box>
  );

  /*
   *   return (
   *     <Box sx={{ display: "flex" }}>
   *       <Box sx={{ width: "20%", minWidth: 200 }}>
   *         {properties.length ? (
   *           <List>
   *             {properties.map((property) => (
   *               <ListItemButton
   *                 key={property._id}
   *                 component={Link}
   *                 to={`/properties/${property._id}`}
   *                 selected={`/properties/${property._id}` === pathname}
   *                 sx={{
   *                   "&.Mui-selected": {
   *                     backgroundColor: "primary.light", // Adjust the color as needed
   *                     color: "white", // Adjust text color as needed
   *                     "&:hover": {
   *                       backgroundColor: "primary.main", // Adjust for hover state as needed
   *                     },
   *                   },
   *                 }}
   *               >
   *                 <ListItemText primary={property.name} />
   *               </ListItemButton>
   *             ))}
   *           </List>
   *         ) : (
   *           <Typography variant="body1" sx={{ fontStyle: "italic" }}>
   *             No properties
   *           </Typography>
   *         )}
   *       </Box>
   *       <Box sx={{ width: "80%" }}>
   *         <Outlet />
   *       </Box>
   *     </Box>
   *   ); */
}
