/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import jwt_decode from "jwt-decode";
import http from "./httpService";
import { useNavigate } from "react-router-dom";
import devConfig from "../config/devConfig.json";
import prodConfig from "../config/prodConfig.json";
const config =
  process.env.REACT_APP_CONFIG_FILE === "prodConfig.json"
    ? prodConfig
    : devConfig;

const apiUrl = config.apiUrl;
const apiEndpoint = apiUrl + "/auth";
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(email, password) {
  const { data: jwt } = await http.post(apiEndpoint, { email, password });
  localStorage.setItem(tokenKey, jwt);
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout(navigate) {
  localStorage.removeItem(tokenKey);
  console.log("logout user, navigate to /splashscreen");
  navigate("/splashScreen");
}

async function validateToken(navigate) {
  const jwt = getJwt();
  if (!jwt) {
    navigate("/splashScreen");
    return false;
  }
  try {
    const response = await http.get(apiUrl + "/properties/validateToken");
    // console.log("/api/properties/validateToken: ", response);
    // The /validateToken route isn't running the auth middleware. This needs
    // to be further investigated. Obviously, having the validateToken route
    // iunside the properties route handler is wrong
    // const response2 = await http.get(apiUrl + "/validateToken");
    // console.log("/api/validateToken: ", response2);
    return response.status === 200;
  } catch (ex) {
    if (ex.response && ex.response.status === 403) {
      console.log("Token validation failed with status 403. Logging out.");
      logout(navigate);
    } else {
      console.log("FAILED to validate token. Caught exception: ", ex);
    }
    return false;
  }
}

export async function getCurrentUser(navigate) {
  try {
    if (!(await validateToken(navigate))) {
      console.log("validateToken failed");
      return null;
    }
    // wrap in try/catch block to handle case where token isn't in localStorage
    const jwt = localStorage.getItem(tokenKey);
    return jwt ? jwt_decode(jwt) : null;
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

const auth = {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};

export default auth;
