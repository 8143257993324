/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  let responseMsg;
  if (error.response && error.response.data) {
    console.log("Error response message: ", error.response.data);
    responseMsg = error.response.data;
  }

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      {responseMsg && <p>{responseMsg}</p>}
    </div>
  );
}
