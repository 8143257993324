import * as yup from "yup";
import { ValidationError } from "yup";
import {
  IAddress,
  Result,
  stateAbbreviations,
} from "@farlosoftware/common-types";

const addressSchema = yup.object().shape({
  number: yup
    .string()
    .matches(
      /^\d+[A-Za-z]?$/,
      "Number must be all digits with an optional single letter afterwards.",
    )
    .min(1)
    .max(24)
    .required(),
  name: yup.string().trim().min(1).max(100).required(),
  type: yup.string().trim().min(2).max(10).required(),
  unit: yup.string().trim().max(20).optional(),
  city: yup.string().trim().min(1).max(100).required(),
  state: yup
    .string()
    .oneOf(stateAbbreviations, "Invalid state abbreviation.")
    .max(2)
    .required(),
  zip: yup
    .string()
    .matches(/^\d{5}$/, "ZIP must be a 5 digit number.")
    .required(),
});

// Use the schema to validate data
export const validateAddress = (address: IAddress): Result<number> => {
  try {
    addressSchema.validateSync(address);
    console.log("validateAddress: success");
    // Proceed with form submission or further processing
    return { success: true, value: 0 };
    //
  } catch (error) {
    if (error instanceof ValidationError) {
      console.error("Validation error:", error.errors);
      // Join the array of strings into a single string
      const errorMessage = error.errors.join("; ");
      return { success: false, error: errorMessage };
    } else {
      console.error("Unexpected error:", error);
      // Return a generic error message or handle unexpected errors differently
      return {
        success: false,
        error: "An unexpected error occurred during validation.",
      };
    }
  }
};
