import loglevel, { LogLevelDesc } from "loglevel";
import debug from "debug";

// Configure loglevel
loglevel.setLevel("info");

// Create debug namespaces
const debugNamespaces = {
  init: debug("app:init"),
  ws: debug("app:ws"),
  auth: debug("app:auth"),
  utils: debug("app:utils"),
  onboarding: debug("app:onboarding"),
};

type Namespace = keyof typeof debugNamespaces;

// Map loglevel levels to numeric values
const logLevelValues: Record<string, number> = {
  trace: 0,
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
};

// Helper function to format data for logging
const formatData = (data: any) => JSON.stringify(data, null, 2);

// Helper function to get the current log level as a string
const getLogLevelString = (): keyof typeof logLevelValues => {
  const levelNumber = loglevel.getLevel();
  const levelName = Object.keys(loglevel.levels).find(
    (key) =>
      loglevel.levels[key as keyof typeof loglevel.levels] === levelNumber,
  );
  return (levelName || "info").toLowerCase() as keyof typeof logLevelValues;
};

const enhancedLog = (
  namespace: Namespace,
  level: LogLevelDesc,
  message: string,
  ...args: any[]
) => {
  // Use loglevel to respect logging levels and avoid duplicate messages
  if (logLevelValues[level] >= logLevelValues[getLogLevelString()]) {
    // Log with debug for colored output
    debugNamespaces[namespace](message, ...args);
  }
};

// Alias functions for convenience in the desired format
const log = {
  trace: (namespace: Namespace, message: string, ...args: any[]) =>
    enhancedLog(namespace, "trace", message, ...args),
  debug: (namespace: Namespace, message: string, ...args: any[]) =>
    enhancedLog(namespace, "debug", message, ...args),
  info: (namespace: Namespace, message: string, ...args: any[]) =>
    enhancedLog(namespace, "info", message, ...args),
  warn: (namespace: Namespace, message: string, ...args: any[]) =>
    enhancedLog(namespace, "warn", message, ...args),
  error: (namespace: Namespace, message: string, ...args: any[]) =>
    enhancedLog(namespace, "error", message, ...args),
};

// Export the enhanced logger and setLevel function
const setLevel = (level: LogLevelDesc) => {
  loglevel.setLevel(level);
};

const keyCountMap: { [key: string]: number } = {};
const logKeyCounter = (namespace: Namespace, key: string) => {
  keyCountMap[key] ??= 0; // if null or undefined, set to 0
  keyCountMap[key]++;
  log.info(namespace, `key: ${keyCountMap[key]}`);
};

export { log, setLevel, formatData, logKeyCounter };
export default log;
