/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import ErrorPage from "./error-page";
import reportWebVitals from "./reportWebVitals";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "react-toastify/dist/ReactToastify.css";
import { ThemeComponent } from "./components/themeComponent";
import { DrawerProvider } from "./components/drawerContext";
import { PropertiesProvider } from "./contexts/propertiesContext";
import { WebSocketProvider } from "./contexts/webSocketContext";
// import RegisterForm from "./components/registerForm";
// import LoginForm from "./components/loginForm";
import Logout from "./components/logout";
import SplashScreen from "./components/splashScreen";
import Onboarding from "./components/onboarding";
import Dashboard from "./routes/dashboard";
import Root from "./routes/root";
import Property from "./routes/property";
import Taxes, { loader as taxesLoader } from "./routes/taxes";
import Ledger, { loader as ledgerLoader } from "./routes/ledger";

import Properties, { loader as propertiesLoader } from "./routes/properties";
import Reports, {
  loader as reportsLoader,
  action as reportsAction,
} from "./routes/reports";
import EditProperty, {
  loader as editPropertyLoader,
} from "./routes/editProperty";
import { SavePropertyProvider } from "./contexts/savePropertyContext";
import AddTax, { action as addTaxAction } from "./routes/addTax";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "splashScreen",
        element: <SplashScreen />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: "ledger",
        element: <Ledger />,
        loader: ledgerLoader,
      },
      {
        path: "taxes",
        element: <Taxes />,
        loader: taxesLoader,
      },
      {
        path: "reports",
        element: <Reports />,
        loader: reportsLoader,
        action: reportsAction,
      },
      {
        path: "onboarding",
        element: <Onboarding />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: ":propertyId/edit",
            element: <EditProperty />,
          },
        ],
      },
      {
        path: "properties",
        element: <Properties />,
        loader: propertiesLoader,
        children: [
          {
            path: ":propertyId",
            element: <Property />,
          },
          {
            path: ":propertyId/edit",
            element: <EditProperty />,
            loader: editPropertyLoader,
          },
          {
            path: ":propertyId/taxes",
            element: <Taxes />,
            loader: taxesLoader,
          },
          {
            path: ":propertyId/taxes/addTax",
            element: <AddTax />,
            action: addTaxAction,
          },
          {
            path: ":propertyId/ledger",
            element: <Ledger />,
            loader: ledgerLoader,
          },
        ],
      },
      {
        path: "/",
        element: <Navigate to="dashboard" />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeComponent>
      <WebSocketProvider>
        <DrawerProvider>
          <PropertiesProvider>
            <SavePropertyProvider>
              <RouterProvider router={router} />
            </SavePropertyProvider>
          </PropertiesProvider>
        </DrawerProvider>
      </WebSocketProvider>
    </ThemeComponent>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
