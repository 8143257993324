/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import http from "./httpService";
import devConfig from "../config/devConfig.json";
import prodConfig from "../config/prodConfig.json";
const config =
  process.env.REACT_APP_CONFIG_FILE === "prodConfig.json"
    ? prodConfig
    : devConfig;

const apiUrl = config.apiUrl;
const apiEndpoint = apiUrl + "/reports";
const monthlyApiEndpoint = apiUrl + "/reports/monthly";

// http://localhost:3900/reports/?properties=Spottswood,Bluecreek,Carolyn&years=2020,2021,2023
function reportUrl(propertyIds, years, combined) {
  let url = apiEndpoint + "/?propertyIds=";
  propertyIds.forEach((propertyId) => {
    url += propertyId.value + ",";
  });
  url = url.slice(0, -1);
  url += "&years=";
  years.forEach((year) => {
    url += year.value + ",";
  });
  url = url.slice(0, -1);
  if (combined) url += "&combined=true";
  console.log(url);
  return url;
}

function monthlyReportUrl(propIds, startDate, numMonths, combined) {
  let url = monthlyApiEndpoint + "/?propertyIds=";
  propIds.forEach((id) => {
    url += id + ",";
  });
  url = url.slice(0, -1);
  url += `&startDate=${startDate}`;
  url += `&numberOfMonths=${numMonths}`;
  if (combined) url += "&combined=true";
  console.log(url);
  return url;
}

export async function runReport(propertyIds, years, combined) {
  return http.get(reportUrl(propertyIds, years, combined));
}

export async function runMonthlyReport(
  propIds,
  startDate,
  numMonths,
  combined,
) {
  return http.get(monthlyReportUrl(propIds, startDate, numMonths, combined));
}
