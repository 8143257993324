/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import http from "./httpService";
import devConfig from "../config/devConfig.json";
import prodConfig from "../config/prodConfig.json";
const config =
  process.env.REACT_APP_CONFIG_FILE === "prodConfig.json"
    ? prodConfig
    : devConfig;

const apiUrl = config.apiUrl;
const apiEndpoint = apiUrl + "/users";

export function register(email, password) {
  return http.post(apiEndpoint, {
    email: email,
    password: password,
  });
}
