import { IProperty } from "@farlosoftware/common-types";
import { getStateName } from "@farlosoftware/common-types";

export const calculateAverageOwnershipTime = (
  properties: IProperty[],
): number => {
  const now = new Date();

  // Filter out properties without a date and calculate the time owned in years
  const ownershipTimes = properties
    .filter((property) => property.date)
    .map((property) => {
      const propertyDate = new Date(property.date!);

      // Calculate the difference in time in UTC
      const timeDiff = now.getTime() - propertyDate.getTime();
      const timeDiffInYears = timeDiff / (1000 * 60 * 60 * 24 * 365.25); // convert milliseconds to years
      return timeDiffInYears;
    });

  // Calculate the average time owned
  const totalOwnershipTime = ownershipTimes.reduce(
    (acc, time) => acc + time,
    0,
  );
  const averageOwnershipTime = totalOwnershipTime / ownershipTimes.length;

  // Handle the case when there are no properties with dates
  return ownershipTimes.length
    ? parseFloat(averageOwnershipTime.toFixed(1))
    : 0;
};

export const calculatePortfolioPurchasePrice = (
  properties: IProperty[],
): number => {
  // Sum up the relevant financial fields for each property
  const totalCashInvested = properties.reduce((total, property) => {
    const salesPrice = property.salesPrice || 0;
    const closingCosts = property.closingCosts || 0;
    const closingCredits = property.closingCredits || 0;
    const insurancePaidAtClosing = property.insurancePaidAtClosing || 0;

    // Total investment for the current property
    const totalInvestmentForProperty =
      salesPrice + closingCosts - closingCredits + insurancePaidAtClosing;

    // Add the current property's investment to the running total
    return total + totalInvestmentForProperty;
  }, 0);

  return totalCashInvested;
};

export const calculateTotalRentCollected = (properties: IProperty[]) => {
  // Implement your calculation logic here
  return 0;
};

export const calculateTotalExpenses = (properties: IProperty[]) => {
  // Implement your calculation logic here
  return 0;
};

export interface PropertyCountByState {
  [state: string]: number;
}

export const countPropertiesByState = (
  properties: IProperty[],
): PropertyCountByState => {
  return properties.reduce((acc, property) => {
    if (property.address && property.address.state) {
      const state =
        getStateName(property.address.state) || property.address.state;
      if (!acc[state]) {
        acc[state] = 0;
      }
      acc[state]++;
    }
    return acc;
  }, {} as PropertyCountByState);
};
