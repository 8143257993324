import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { BeatLoader } from "react-spinners";
import { getProperties } from "../services/propertyService";
import { runMonthlyReport } from "../services/reportService";
import { useWebSocket } from "src/contexts/webSocketContext";
import {
  PortfolioMonthlyReport,
  MonthReport,
  IProperty,
} from "@farlosoftware/common-types";

// Custom formatter function for Y-axis
const dollarFormatter = (value: number) => {
  return `$${value.toLocaleString()}`;
};

// Custom formatter for the tooltip
const tooltipFormatter = (value: number, name: string, props: any) => {
  return [`$${value.toLocaleString()}`, name];
};

function getStartDateString(report: MonthReport) {
  const jsStart = new Date(report.startJsdVo);
  console.log("jsStart = ", jsStart);
  const year = jsStart.getUTCFullYear();
  const month = jsStart.getUTCMonth() + 1; // Adding 1 to get the actual month

  // Create the string in the "YYYY.MM" format
  return `${year}.${month < 10 ? "0" : ""}${month}`;
}

function fixReportDates(allMonthsReport: PortfolioMonthlyReport[]) {
  allMonthsReport.forEach((theMonthlyReport) => {
    theMonthlyReport.reports.forEach((propertyReport) => {
      const reportString = getStartDateString(propertyReport);
      console.log("reportString: ", reportString);
    });
  });
}

function getStartMonth() {
  const now = new Date();
  const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  const startMonth = new Date(
    lastMonth.getFullYear(),
    lastMonth.getMonth() - 11,
    1,
  );

  const year = startMonth.getFullYear();
  const month = String(startMonth.getMonth() + 1).padStart(2, "0"); // Ensure month is two digits

  return `${year}.${month}`;
}

const startMonth = getStartMonth();

const PerfChart = () => {
  const { portfolioReports, isConnected, error: wsError } = useWebSocket();
  const [properties, setProperties] = useState<IProperty[]>([]);
  const [reports, setReports] = useState<PortfolioMonthlyReport[]>([]);
  const [loadingProperties, setLoadingProperties] = useState(true);
  const [loadingReport, setLoadingReport] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const debouncedFetchProperties = useCallback(
    debounce(async () => {
      setLoadingProperties(true);
      try {
        console.log("perfChart: calling getProperties()");
        const result = await getProperties();
        if (result.success && result.value) {
          setProperties(result.value);
          setError(null); // Clear error state on success
        } else {
          setError("Failed to get properties");
        }
      } catch (error: any) {
        if (error.code === "ECONNABORTED") {
          console.log("Request aborted", error.message); // Handle request abortion gracefully
        } else {
          console.log(
            `Properties useEffect(): Caught exception: ${error.message}`,
          );
          console.log("Error details: ", error); // Log the entire error object for context
          setError("An unknown error occurred while fetching properties");
        }
      }
      setLoadingProperties(false);
    }, 300), // The debounce delay in milliseconds (300ms in this example)
    [], // Empty dependency array to ensure it's only created once
  );

  /* useEffect(() => {
  *   const source = axios.CancelToken.source();
  *   async function fetchProperties() {
  *     setLoadingProperties(true);
  *     try {
  *       console.log("perfChart: calling getProperties()");
  *       const result = await getProperties();
  *       if (result.success && result.value) {
  *         setProperties(result.value);
  *         setError(null); // Clear error state on success
  *       } else {
  *         setError("Failed to get properties");
  *       }
  *     } catch (error) {
  *       if (axios.isCancel(error)) {
  *         console.log("Axios Request canceled", error.message);
  *       } else {
  *         setError("An unknown error occurred while fetching properties");
  *       }
  *     }
  *     setLoadingProperties(false);
  *   }
  
  *   fetchProperties();
  
  *   return () => {
  *     source.cancel("perfChart: Component unmounted");
  *   };
  * }, []); */

  const debouncedFetchReport = useCallback(
    debounce(async (propIds) => {
      if (propIds.length === 0) return;

      setLoadingReport(true);
      try {
        console.log("perfChart: calling runMonthlyReport()");
        const reportResult = await runMonthlyReport(
          propIds,
          startMonth,
          12, // duration (number of months)
          true, // includeCombinedReport
        );
        console.log("reportResult: ", reportResult);
        if (reportResult.status !== 200) {
          console.log("Error running monthly report: ", reportResult.status);
          setError("Error running monthly report");
        } else {
          const portfolioMonthlyReports = reportResult.data;
          fixReportDates(portfolioMonthlyReports);
          setReports(portfolioMonthlyReports);
          setError(null); // Clear error state on success
        }
      } catch (error: any) {
        // Type assertion to any
        if (error.code === "ECONNABORTED") {
          console.log("Request aborted", error.message); // Handle request abortion gracefully
        } else {
          console.log(
            `fetchReport useEffect(): Caught exception: ${error.message}`,
          );
          console.log("Error details: ", error); // Log the entire error object for context
          const errorMessage = handleAxiosError(error);
          setError(
            `fetchReport useEffect(): Caught exception: ${errorMessage}`,
          );
        }
      }
    }, 300), // The debounce delay in milliseconds (300ms in this example)
    [], // Empty dependency array to ensure it's only created once
  );

  /* useEffect(() => {
  *   const source = axios.CancelToken.source();
  
  *   async function fetchReport() {
  *     if (properties.length === 0) return;
  
  *     setLoadingReport(true);
  
  *     const propIds = properties.map((prop) => prop._id);
  *     try {
  *       console.log("perfChart: calling runMonthlyReport()");
  *       const reportResult = await runMonthlyReport(
  *         propIds,
  *         "2023.05", // start month
  *         12, // duration (number of months)
  *         true, // includeCombinedReport
  *       );
  *       console.log("reportResult: ", reportResult);
  *       if (reportResult.status !== 200) {
  *         console.log("Error running monthly report: ", reportResult.status);
  *         setError("Error running monthly report");
  *       } else {
  *         const portfolioMonthlyReports = reportResult.data;
  *         fixReportDates(portfolioMonthlyReports);
  *         setReports(portfolioMonthlyReports);
  *         setError(null); // Clear error state on success
  *       }
  *     } catch (error) {
  *       if (axios.isCancel(error)) {
  *         console.log("Request canceled", error.message);
  *       } else {
  *         console.log(`fetchReport useEffect(): Caught exception: ${error}`);
  *         const errorMessage = handleAxiosError(error);
  *         setError(
  *           `fetchReport useEffect(): Caught exception: ${errorMessage}`,
  *         );
  *       }
  *     }
  *   }
  
  *   if (properties.length > 0) {
  *     fetchReport();
  *   }
  *   return () => {
  *     source.cancel("Component unmounted");
  *   };
  * }, [properties]); */

  useEffect(() => {
    debouncedFetchProperties();
  }, [debouncedFetchProperties]);

  useEffect(() => {
    if (properties.length > 0) {
      const propIds = properties.map((prop) => prop._id);
      debouncedFetchReport(propIds);
    }
  }, [properties, debouncedFetchReport]);

  useEffect(() => {
    if (portfolioReports) {
      setReports(portfolioReports);
      console.log(
        "Received PortfolioMonthlyReport data:",
        portfolioReports.length,
      );
      setLoadingReport(false);
      setError(null); // Clear error state on success
    }
  }, [portfolioReports]);

  const handleAxiosError = (error: any): string => {
    if (error.response) {
      console.error("Error response:", error.response);
      return `Error: ${error.response.status} ${error.response.statusText}`;
    } else if (error.request) {
      console.error("handleAxiosError: error.request:", error.request);
      return "Network error. Please check your connection.";
    } else {
      console.error("Error message:", error.message);
      return "An unknown error occurred.";
    }
  };

  if (loadingReport) {
    return (
      <div>
        <h2 style={{ textAlign: "center" }}>Performance Chart</h2>
        <div className="spinner-container">
          <BeatLoader color={"#36D7B7"} loading={true} />
        </div>
      </div>
    );
  }

  if (error || wsError) {
    console.log("perfChart error: ", error);
    console.log("perfChart wsError: ", wsError);
    if (!portfolioReports) return <div>Error: {error || wsError}</div>;
  }

  /* if (errorMessage) {
   *   return (
   *     <div
   *       style={{
   *         width: "100%",
   *         maxWidth: "800px",
   *         height: "400px",
   *         maxHeight: "500px",
   *       }}
   *     >
   *       <h2 style={{ textAlign: "center" }}>Error Encountered</h2>
   *       <p style={{ textAlign: "center" }}>{errorMessage}</p>
   *     </div>
   *   );
   * } */

  /* console.log("PerfChart: properties: ", properties); */
  /* console.log("PerfChart: report: ", portfolioReports); */
  /* console.log(
   *   "  Num months returns: ",
   *   portfolioReports ? portfolioReports.length : "loading...",
   * ); */
  const combineArray = portfolioReports
    ? portfolioReports.map((monthReport: PortfolioMonthlyReport) => {
        const len = monthReport.reports.length;
        // Last report is the combined report
        return monthReport.reports[len - 1];
      })
    : [];
  /* console.log("combineArray: ", combineArray); */

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Performance Chart</h2>
      {loadingReport ? (
        <div className="spinner-container">
          <BeatLoader color={"#36D7B7"} loading={true} />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={combineArray}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dateString" />
            <YAxis tickFormatter={dollarFormatter} />
            <Tooltip formatter={tooltipFormatter} />
            <Legend />
            <Line
              type="monotone"
              dataKey="revenue"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="expenses"
              stroke="red"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="income"
              stroke="green"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default PerfChart;
