export function formatUTCDate(dateString: string): string {
  const dateObj = new Date(dateString);
  const year = dateObj.getUTCFullYear();
  const month = dateObj.getUTCMonth() + 1; // getUTCMonth() returns 0-11
  const day = dateObj.getUTCDate();

  return `${month}/${day}/${year}`;
}

export function utcDateStringToUsDateString(utcDateString: string): string {
  if (!utcDateString || utcDateString.length < 1) return "";

  // Get the date in a usable format (TODO: clean this up)
  let date = "";
  const dateObj = new Date(utcDateString);
  if (isNaN(dateObj.getTime())) {
    return ""; // return empty string if Date conversion failed
  }
  const month2Digit = ("0" + (dateObj.getUTCMonth() + 1)).slice(-2);
  const day2Digit = ("0" + dateObj.getUTCDate()).slice(-2);
  date = `${dateObj.getUTCFullYear()}-${month2Digit}-${day2Digit}`;

  return date;
}

export function formatDateStrToMDY(dateStr: string): string {
  const date = new Date(dateStr);
  // Extract the UTC components of the date
  const utcYear = date.getUTCFullYear();
  const utcMonth = date.getUTCMonth() + 1; // getUTCMonth() returns month from 0-11
  const utcDay = date.getUTCDate();

  // Format the date components to "month/day/year"
  const formattedDate = `${utcMonth}/${utcDay}/${utcYear}`;
  return formattedDate;
}

export function convertDateToISOString(date: Date | string): string {
  return date instanceof Date ? date.toISOString() : date;
}
