/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import React from "react";
import { useLoaderData } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { getPropertyTaxes, getProperty } from "../services/propertyService";
import { getTaxes } from "../services/taxService";
import { numberToUSD } from "../utils/formatting";
import { formatUTCDate } from "../utils/dateUtils";

export async function loader({ params }) {
  let property = null;
  let taxes = null;

  if (!params.propertyId) {
    // console.log("params.propertyId is null, get all taxes");
    ({ data: taxes } = await getTaxes());
    // console.log("taxes: ", taxes);
  } else {
    ({ data: taxes } = await getPropertyTaxes(params.propertyId));
    property = await getProperty(params.propertyId);
  }
  return { property, taxes };
}

export default function Taxes() {
  const loaderData = useLoaderData();
  const taxes = loaderData.taxes;
  const property = loaderData.property;
  const addTaxURL = property && `/properties/${property._id}/taxes/addTax`;

  const columns = [
    // { field: "index", headerName: "#", width: 70 },
    {
      field: "paymentDate",
      headerName: "Date Paid",
      width: 130,
      valueFormatter: ({ value }) => formatUTCDate(value),
    },
    { field: "propertyName", headerName: "Property", width: 200 },
    {
      field: "taxingAuthority",
      headerName: "Taxing Authority",
      width: 130,
    },
    { field: "taxYear", headerName: "Tax Year", width: 130 },
    {
      field: "amount",
      headerName: "Amount",
      width: 130,
      valueFormatter: ({ value }) => numberToUSD(value),
    },
  ];

  const rows = taxes.map((tax, index) => ({
    id: tax._id,
    index: index + 1,
    // GET "/taxes" route populates the propertyId field with its IProperty object
    propertyName: property ? property.name : tax.propertyId.name,
    taxingAuthority: tax.taxingAuthority,
    paymentDate: tax.paymentDate,
    taxYear: tax.taxYear,
    amount: tax.amount,
  }));

  return (
    <Box
      sx={{
        width: "100%", // Default to full width on smaller screens
        p: 2,
      }}
    >
      <Typography variant="h4">{property && property.name} Taxes</Typography>
      <Box display="flex" flexDirection="column" alignItems="flex-start" my={2}>
        {taxes.length > 0 ? (
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              /* checkboxSelection */
            />
          </div>
        ) : (
          <Typography>No tax records for this property</Typography>
        )}
        {addTaxURL && (
          <div>
            <Button
              component={Link}
              to={addTaxURL}
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
            >
              Add Tax
            </Button>
          </div>
        )}
      </Box>
    </Box>
  );
}
