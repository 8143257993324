/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { AxiosError } from "axios";
import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useLoaderData /*, redirect, Link */ } from "react-router-dom";
import { getProperty } from "../services/propertyService";
import { getPropertyLedger } from "../services/propertyService";
import { getLedger } from "../services/transaction";
import { useUploadLedger } from "../hooks/useUploadLedger";
import { formatUTCDate } from "../utils/dateUtils";
import { BeatLoader } from "react-spinners";
// import { numberToUSD } from "../utils/formatting";
// import { getTaxLedger } from "../services/taxService";

export async function loader({ params }) {
  let property = null;
  let ledger = null;

  if (!params.propertyId) {
    console.log("params.propertyId is null, get entire ledger");
    ledger = await getLedger();
  } else {
    ledger = await getPropertyLedger(params.propertyId);
    property = await getProperty(params.propertyId);
  }
  return { property: property, ledger: ledger };
}

function filterLedger(ledger, yearFilter) {
  if (!yearFilter) return ledger;
  return ledger.filter((t) => {
    const date = new Date(t.date);
    if (date.getFullYear() === yearFilter) return true;
    return false;
  });
}

export default function Ledger() {
  const loaderData = useLoaderData();
  const handleUpload = useUploadLedger();
  const ledger = loaderData.ledger;
  const property = loaderData.property;
  // const header = [
  //   { label: "#", path: "index" },
  //   { label: "Type", path: "type" },
  //   { label: "Date", path: "date" },
  //   { label: "Amount", path: "amount" },
  //   { label: "Details", path: "details" },
  //   { label: "Description", path: "description" },
  //   { label: "Location", path: "location" },
  //   { label: "Payee", path: "payee" },
  //   { label: "RefNumber", path: "refNumber" },
  // ];
  const [yearFilter, setYearFilter] = useState(null);
  const [filtered, setFiltered] = useState(ledger);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState("");

  const columns = [
    // { field: "index", headerName: "#", width: 70 },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      valueFormatter: ({ value }) => formatUTCDate(value),
    },
    { field: "amount", headerName: "Amount", width: 95 },
    { field: "details", headerName: "Details", width: 200 },
    { field: "description", headerName: "Description", width: 140 },
    { field: "payee", headerName: "Payee", width: 130 },
    { field: "refNumber", headerName: "RefNumber", width: 130 },
    { field: "type", headerName: "Type", width: 130 },
    { field: "location", headerName: "Location", width: 260 },
  ];

  useEffect(() => {
    if (yearFilter) setFiltered(filterLedger(ledger, yearFilter));
  }, [yearFilter]);

  function handleDropdownClick(event) {
    const year = parseInt(event.target.value);
    if (isNaN(year)) {
      setYearFilter(null);
      return;
    }
    setYearFilter(year);
  }

  function handleFileChange(e) {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (selectedFile) {
      setIsUploading(true); // Show spinner
      try {
        // Implement your file upload logic here
        console.log("File uploading...");
        const result = await handleUpload(selectedFile);
        console.log("Upload complete, success: ", result);
      } catch (error) {
        console.error("Error uploading file:", error);
        if (error && error instanceof AxiosError && error.response?.data) {
          setUploadError(
            `Error uploading file: ${error.message}, ${error.response.data}`,
          );
        } else {
          setUploadError("Unknown error occurred while uploading file.");
        }
      } finally {
        setIsUploading(false); // Hide spinner
      }
    }
  }

  return (
    <Box
      sx={{
        width: "100%", // Default to full width on smaller screens
        p: 2,
      }}
    >
      <Typography variant="h4">{property && property.name} Ledger</Typography>
      <Box display="flex" alignItems="center" my={2}>
        <input
          accept=".xls,.csv"
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span">
            Browse...
          </Button>
        </label>
        <TextField
          disabled
          label="Selected File"
          value={fileName}
          variant="outlined"
          style={{ margin: "0 10px" }}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedFile}
          onClick={handleSubmit}
        >
          Upload
        </Button>
      </Box>
      {isUploading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "2.3rem",
            marginTop: "-4px", // Adjust this value as needed
          }}
        >
          <p style={{ margin: "3", marginRight: "10px" }}>
            Loading Ledger transactions...
          </p>
          <BeatLoader color={"#36D7B7"} loading={true} />
        </div>
      ) : uploadError ? (
        <div style={{ marginTop: "10px", color: "red" }}>{uploadError}</div>
      ) : (
        <div>
          <p> </p>
        </div>
      )}

      {/* Year filter */}
      <Box mb={2} width="200px">
        <FormControl fullWidth>
          <InputLabel id="year-select-label">Year</InputLabel>
          <Select
            labelId="year-select-label"
            id="year-select"
            value={yearFilter || ""}
            onChange={handleDropdownClick}
            label="Year"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value={2019}>2019</MenuItem>
            <MenuItem value={2020}>2020</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2024}>2024</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filtered.map((item, index) => ({ ...item, index: index + 1 }))}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          /* checkboxSelection */
          getRowId={(row) => row._id} // Use the _id field as the unique identifier
          // ... other DataGrid props as needed
        />
      </div>
    </Box>
  );
}
