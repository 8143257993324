import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
  makeStyles,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useTheme } from "@mui/material/styles";
import { useDrawerContext } from "../components/drawerContext";

// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     background: theme.palette.primary.main,
//   },
// }));

const Navbar = ({ darkMode, toggleTheme, user }) => {
  const { drawerOpen, handleDrawerToggle } = useDrawerContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "inherit" : "primary"; // Use 'primary' for dark text in
  // const classes = useStyles();
  const [appBarColor, setAppBarColor] = useState(
    darkMode ? "#000000" : theme.palette.primary.main,
  );

  useEffect(() => {
    // Update appBarColor whenever darkMode changes
    setAppBarColor(darkMode ? "#000000" : theme.palette.primary.main);
  }, [darkMode, theme.palette.primary.main]);

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: appBarColor /* theme.palette.background.default */,
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          LeaseLogic
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="mode"
            onClick={toggleTheme}
          >
            {darkMode ? <Brightness7Icon /> : <Brightness3Icon />}
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
