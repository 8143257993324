import React, { useState, createContext, useContext } from "react";

// Create the context
export const DrawerContext = createContext();

// Create a provider component
export const DrawerProvider = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <DrawerContext.Provider value={{ drawerOpen, handleDrawerToggle }}>
      {children}
    </DrawerContext.Provider>
  );
};

// Hook to use the drawer context
export const useDrawerContext = () => useContext(DrawerContext);
