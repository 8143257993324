import React, { createContext, useContext, useState } from "react";

const SavePropertyContext = createContext(null);

export const SavePropertyProvider = ({ children }) => {
  const [savePropertyCallback, setSavePropertyCallback] = useState(null);

  return (
    <SavePropertyContext.Provider
      value={{ savePropertyCallback, setSavePropertyCallback }}
    >
      {children}
    </SavePropertyContext.Provider>
  );
};

export const useSavePropertyContext = () => useContext(SavePropertyContext);
