// OnboardingContent.tsx
import React, { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import { AxiosError } from "axios";
import { Outlet, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CheckboxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";
import { useUploadLedger } from "../hooks/useUploadLedger";
import { BeatLoader } from "react-spinners";
import { IdString } from "@farlosoftware/common-types";
import {
  validateProperty,
  validateProperties,
} from "../services/propertyUtils";
import { getProperties } from "../services/propertyService";
import { usePropertiesContext } from "../contexts/propertiesContext";
import { useSavePropertyContext } from "../contexts/savePropertyContext";

const Onboarding: React.FC = () => {
  const { properties, updateProperties } = usePropertiesContext();
  const handleUpload = useUploadLedger();
  const [error, setError] = useState<string | null>(null);
  // Define a state for each step's completion status
  const [stepCompletion, setStepCompletion] = useState({
    step1: false, // download ledger
    step2: false, // upload ledger[
    step3: false, // add property details
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  // Another stepCompletion for each property
  const [propertyStepCompletion, setPropertyStepCompletion] = useState<
    boolean[]
  >([]);
  const [expandedProperty, setExpandedProperty] = useState("");
  const [uploadError, setUploadError] = useState("");
  const { savePropertyCallback } = useSavePropertyContext();
  const [isSaving, setIsSaving] = useState(false);
  /* const [onboardingComplete, setOnboardingComplete] = useState(false); */
  const navigate = useNavigate();

  /* console.log("Onboarding Component: properties: ", properties); */

  const fetchProperties = async () => {
    try {
      const result = await getProperties(); // Assuming getProperties is your API call
      if (!result.success || !result.value) {
        setError("Failed to get properties");
      } else {
        console.log(
          "Got properties, about to updateProperties: ",
          result.value,
        );
        updateProperties(result.value);
      }
    } catch (err: unknown) {
      if (typeof err === "object" && err !== null && "message" in err) {
        setError((err as Error).message || "An error occurred");
      } else {
        // Handle the case where 'err' doesn't have a 'message' property.
        setError("An error occurred");
      }
    }
  };

  useEffect(() => {
    fetchProperties();
  }, [updateProperties]);

  useEffect(() => {
    console.log("Updating properties step completion");
    // Programmatically set the property step completion based on validation
    if (properties) {
      console.log("  ", properties);
      const updatedStepCompletion = properties.map((property) =>
        validateProperty(property),
      );
      setPropertyStepCompletion(updatedStepCompletion);
    }
  }, [properties]);

  useEffect(() => {
    console.log("Updating step completion");
    // Check the state of the properties array and set stepCompletion accordingly
    if (properties && properties.length > 0) {
      console.log("  ", properties);
      // Set step1 and step 2 to true if there are properties
      setStepCompletion((prev) => ({ ...prev, step1: true }));
      setStepCompletion((prev) => ({ ...prev, step2: true }));

      // You can add more conditions based on your requirements
      // For example, set step2 to true if a specific condition is met
      const result = validateProperties(properties);
      if (result.success) {
        // Congrats, they've completed onboarding!
        console.log("Onboarding is complete! Navigating to root route.");
        setStepCompletion((prev) => ({ ...prev, step3: true }));
        /* setOnboardingComplete(true); */
        navigate("/");
        window.scrollTo(0, 0);
      }
    }
  }, [properties, navigate]);

  const handlePropertyClick = async (propertyId: IdString) => {
    if (isSaving) return;

    if (expandedProperty && savePropertyCallback) {
      setIsSaving(true);
      const saveResult = await savePropertyCallback();
      setIsSaving(false);
      if (!saveResult) {
        console.log("Save Property failed, cannot collapse.");
        return;
      }
    }

    console.log("handlePropertyClick: IdString: ", propertyId);
    setExpandedProperty((prevExpanded) =>
      prevExpanded === propertyId ? "" : propertyId,
    );

    navigate(`${propertyId}/edit`);
  };

  // Function to display either a checked or unchecked icon based on step completion
  const renderCheckboxIcon = (stepCompleted: boolean) => {
    return stepCompleted ? (
      <CheckboxIcon sx={{ marginTop: "-3px" }} />
    ) : (
      <CheckBoxOutlineBlankIcon sx={{ marginTop: "-3px" }} />
    );
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  const handleFileUpload = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (selectedFile) {
      setIsUploading(true); // Show spinner
      try {
        // Implement your file upload logic here
        console.log("File uploading...");
        const result = await handleUpload(selectedFile);
        console.log("Upload complete, success: ", result);
      } catch (error) {
        console.error("Error uploading file:", error);
        if (error && error instanceof AxiosError && error.response?.data) {
          setUploadError(
            `Error uploading file: ${error.message}, ${error.response.data}`,
          );
        } else {
          setUploadError("Unknown error occurred while uploading file.");
        }
      } finally {
        setIsUploading(false); // Hide spinner
      }
    }
  };

  const handleBrowseClick = () => {
    // Clear the uploadError when Browse is clicked
    setUploadError("");
    // Implement other logic for file browsing if needed
  };

  /* const handleDoneClick = async (e: MouseEvent<HTMLButtonElement>) => {
   *   e.preventDefault();
   *   if (!onboardingComplete)
   *     console.log("Error: handleDoneClick when onboardingComplete is false");
   *   navigate("/");
   * }; */

  return (
    <div>
      <h2 style={{ marginBottom: "2px" }}>Onboarding Steps</h2>
      <List
        sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, marginLeft: 0 }}
      >
        <ListItem
          style={{ cursor: "default" }}
          sx={{ paddingLeft: 0, marginLeft: 1 }}
        >
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {renderCheckboxIcon(stepCompletion.step1)}
              <Typography variant="body1">
                Download Ledger from REI Nation Portal
              </Typography>
            </Stack>
            <Typography
              component="div"
              variant="body2"
              sx={{ mt: 0, paddingLeft: "2.3rem" }}
            >
              <ol style={{ paddingLeft: "1rem" }}>
                <li>
                  Log in to the{" "}
                  <a
                    href="https://app.propertyware.com/pw/index.html#/login/owner/ppmgtexas"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <b>REI Nation Portal</b>
                  </a>
                </li>
                <li>
                  Click <b>My Ledger</b> on the left side of the{" "}
                  <b>REI Nation Portal</b> page
                </li>
                <li>
                  Click the <b>Filter</b> button on the top right of the{" "}
                  <b>My Ledger</b> page
                </li>
                <li>
                  In the <b>Filters</b> menu, ensure all buildings are selected
                  (this is the default) and click the <b>Period</b> dropdown.
                </li>
                <li>
                  In the Period dropdown, select <b>Custom Date Range</b>
                </li>
                <li>
                  In <b>Custom Date Range</b> select a <b>Start Date</b> prior
                  to your first property purchase and leave the <b>End Date</b>{" "}
                  as today's date.
                </li>
                <li>
                  Click the <b>Apply</b> button on the bottom right
                </li>
                <li>
                  Wait for all the Ledger entries to load{" "}
                  {"(could take more than 1 "}
                  minute if you have a lot of transactions{")"}
                </li>
                <li>
                  Click the
                  <span
                    className="fa fa-download"
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                  ></span>
                  button just above the ledger table on the right
                </li>
                <li>
                  Choose filename for the .xls file and remember where you save
                  it
                </li>
              </ol>
              {/* Add more list items as needed */}
            </Typography>
          </Stack>
        </ListItem>
        <ListItem
          style={{ cursor: "default" }}
          sx={{ paddingLeft: 0, paddingBottom: 0, marginLeft: 1 }}
        >
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {renderCheckboxIcon(stepCompletion.step2)}
              <Typography variant="body1">
                Upload Ledger to LeaseLogic
              </Typography>
            </Stack>
            <Box
              display="flex"
              alignItems="center"
              my={2}
              sx={{ mt: 0, paddingLeft: "2.3rem" }}
            >
              <input
                accept=".xls,.csv"
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  component="span"
                  onClick={handleBrowseClick}
                >
                  Browse...
                </Button>
              </label>
              <TextField
                disabled
                label="Selected File"
                value={fileName}
                variant="outlined"
                style={{ margin: "0 10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedFile}
                onClick={handleFileUpload}
              >
                Upload
              </Button>
            </Box>
            {isUploading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "2.3rem",
                  marginTop: "-4px", // Adjust this value as needed
                }}
              >
                <p style={{ margin: "3", marginRight: "10px" }}>
                  Loading Ledger transactions...
                </p>
                <BeatLoader color={"#36D7B7"} loading={true} />
              </div>
            ) : uploadError ? (
              <div style={{ marginTop: "10px", color: "red" }}>
                {uploadError}
              </div>
            ) : (
              <div>
                <p> </p>
              </div>
            )}
          </Stack>
        </ListItem>
        <ListItem
          style={{ cursor: "default" }}
          sx={{ paddingLeft: 0, marginLeft: 1, display: "block" }}
        >
          <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
            {renderCheckboxIcon(stepCompletion.step3)}
            <ListItemText primary="Add Property Details" />
          </Box>
          <List
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              marginLeft: 2,
            }}
          >
            {properties &&
              properties.map((property, index) => (
                <React.Fragment key={property._id}>
                  <ListItem
                    key={index}
                    sx={{ paddingLeft: 0, marginLeft: 0.5 }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ gap: 1, cursor: "pointer" }}
                      onClick={() => handlePropertyClick(property._id)}
                    >
                      {renderCheckboxIcon(propertyStepCompletion[index])}
                      {/* You can use the property data to render the ListItem */}
                      <ListItemText primary={`${property.name}`} />
                    </Box>
                    {/* You can also add checkboxes or icons based on stepCompletion */}
                  </ListItem>

                  <Collapse
                    in={expandedProperty === property._id}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      // Adjust the left margin or padding as needed
                      marginLeft: 0, // or any other value that suits your layout
                      paddingLeft: 0, // or any other value that suits your layout
                    }}
                  >
                    <Outlet />
                  </Collapse>
                </React.Fragment>
              ))}
          </List>
        </ListItem>
      </List>
      {/* <Box display="flex" alignItems="center" sx={{ ml: 1, gap: 0, mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!onboardingComplete}
          onClick={handleDoneClick}
        >
          Done
        </Button>
      </Box> */}
    </div>
  );
};

export default Onboarding;
