/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import http from "./httpService";
import devConfig from "../config/devConfig.json";
import prodConfig from "../config/prodConfig.json";
const config =
  process.env.REACT_APP_CONFIG_FILE === "prodConfig.json"
    ? prodConfig
    : devConfig;

const apiUrl = config.apiUrl;
const apiEndpoint = apiUrl + "/transactions";
const uploadLedgerURL = apiEndpoint + "/uploadLedger";
const ledgerYearsURL = apiEndpoint + "/ledgerYears";

function ledgerUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export async function getLedger() {
  const { data: ledger } = await http.get(apiEndpoint);
  return ledger;
}

export async function getLedgerYears() {
  const { data: ledgerYears } = await http.get(ledgerYearsURL);
  return ledgerYears;
}

export async function getTransaction(transactionId) {
  return http.get(ledgerUrl(transactionId));
}

export async function uploadLedger(ledgerFile) {
  let formData = new FormData();
  formData.append("file", ledgerFile);
  const response = await http.post(uploadLedgerURL, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  console.log("response: ", response);
  return response;
}
