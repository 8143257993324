import devConfig from "../config/devConfig.json";
import prodConfig from "../config/prodConfig.json";

interface Config {
  apiUrl: string;
  socketUrl: string;
}

const config: Config =
  process.env.REACT_APP_CONFIG_FILE === "prodConfig.json"
    ? prodConfig
    : devConfig;

export default config;
