import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Container,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import Joi from "joi";
import axios from "axios";
import auth from "../services/authService";
import * as userService from "../services/userService.js";
import { IUser } from "@farlosoftware/common-types";

const SplashScreen: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Define an async function inside the useEffect
    const fetchCurrentUser = async () => {
      const user = await auth.getCurrentUser();
      console.log("SplashScreen, user: ", user);
      if (user) {
        setCurrentUser(user);
        navigate("/");
      }
    };

    // Call the async function
    fetchCurrentUser();
  }, [navigate]); // Assuming 'setCurrentUser' is also a dependency, you might want to include it in the dependency array

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const LoginForm: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const schema = Joi.object({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .label("Email"),
      password: Joi.string().required().label("Password"),
    });

    const validate = () => {
      const { error } = schema.validate(
        { email, password },
        { abortEarly: false },
      );

      if (!error) return null;

      const errors: { [key: string]: string } = {};
      for (let item of error.details) {
        errors[item.path[0]] = item.message;
      }
      return errors;
    };

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const validationErrors = validate();
      setErrors(validationErrors || {});
      if (validationErrors) return;

      // Proceed with the login process
      try {
        await auth.login(email, password);
        // causes full refresh -> componentDidMount will be called */
        window.location.href = "/";
      } catch (ex) {
        if (axios.isAxiosError(ex) && ex.response) {
          if (ex.response.status === 400) {
            // invalid email or password
            // Assuming the error data is a string; adjust as needed based on
            // your API's error structure
            const errorMsg =
              typeof ex.response.data === "string"
                ? ex.response.data
                : "Login error";
            console.log("Exception Caught: ", errorMsg);
            setErrors({
              ...errors,
              loginError: errorMsg || "Invalid credentials. Please try again.",
            });
          }
        } else {
          // Handle non-Axios errors
          setErrors({
            ...errors,
            loginError:
              "Unkown error logging in user. Please contact system admin.",
          });
        }
      }
    };

    return (
      <Box p={3}>
        <Typography variant="h6">Login</Typography>
        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
          />
          {errors.loginError && (
            <Typography color="error" style={{ marginTop: 8 }}>
              {errors.loginError}
            </Typography>
          )}
          <Button variant="contained" color="primary" fullWidth type="submit">
            Log In
          </Button>
        </form>
      </Box>
    );
  };

  const RegisterForm: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const schema = Joi.object({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .label("Email"),
      password: Joi.string().required().label("Password"),
    });

    const validateEmail = (email: string) => {
      const { error } = Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .label("Email")
        .validate(email);

      return error ? error.message : "";
    };

    const validatePassword = (password: string) => {
      const { error } = Joi.string()
        .required()
        .label("Password")
        .validate(password);

      return error ? error.message : "";
    };

    const validateConfirmPassword = (confirmPassword: string) => {
      if (password !== confirmPassword) {
        return "Passwords must match";
      }
      return "";
    };

    const handleFieldChange = (name: string, value: string) => {
      if (name === "email") {
        setEmail(value);
        setErrors({ ...errors, email: validateEmail(value) });
      } else if (name === "password") {
        setPassword(value);
        setErrors({
          ...errors,
          password: validatePassword(value),
          confirmPassword: validateConfirmPassword(confirmPassword),
        });
      } else if (name === "confirmPassword") {
        setConfirmPassword(value);
        setErrors({
          ...errors,
          confirmPassword: validateConfirmPassword(value),
        });
      }
    };

    const validate = () => {
      const validationErrors: { [key: string]: string } = {};

      const { error } = schema.validate(
        { email, password },
        { abortEarly: false },
      );

      if (error) {
        for (let item of error.details) {
          validationErrors[item.path[0]] = item.message;
        }
      }

      // Additional validation for confirm password
      if (password !== confirmPassword) {
        validationErrors.confirmPassword = "Passwords must match";
      }

      return Object.keys(validationErrors).length === 0
        ? null
        : validationErrors;
    };

    const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const validationErrors = validate();
      setErrors(validationErrors || {});
      if (validationErrors) {
        console.log("Validation Errors: ", validationErrors);
        return;
      }
      // Proceed with the registration process
      try {
        const response = await userService.register(email, password);
        console.log("response: ", response);
        auth.loginWithJwt(response.headers["x-auth-token"]);
        // causes full refresh -> componentDidMount will be called */
        window.location.href = "/";
      } catch (ex) {
        console.log("Caught error: ", ex);
        if (axios.isAxiosError(ex) && ex.response) {
          if (ex.response.status === 400 || ex.response.status === 401) {
            // Assuming the error data is a string; adjust as needed based on
            // your API's error structure
            const errorMsg =
              typeof ex.response.data === "string"
                ? ex.response.data
                : "Registration error";
            setErrors({
              ...errors,
              registrationError:
                errorMsg ||
                "Error registering user. Please contact system admin.",
            });
          }
        } else {
          // non-axios error handler
          setErrors({
            ...errors,
            registrationError:
              "Unkown rrror registering user. Please contact system admin.",
          });
        }
      }
    };

    return (
      <Box p={3}>
        <Typography variant="h6">Register</Typography>
        <form onSubmit={handleRegister}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            error={!!errors.email}
            helperText={errors.email}
            onChange={(e) => handleFieldChange("email", e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            error={!!errors.password}
            helperText={errors.password}
            onChange={(e) => handleFieldChange("password", e.target.value)}
          />
          <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            onChange={(e) =>
              handleFieldChange("confirmPassword", e.target.value)
            }
          />
          {errors.registrationError && (
            <Typography color="error" style={{ marginTop: 8 }}>
              {errors.registrationError}
            </Typography>
          )}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Register
          </Button>
        </form>
      </Box>
    );
  };
  return (
    <Container component="main" maxWidth="xs">
      {/* maxWidth can be "xs", "sm", "md", "lg", "xl" */}
      <Paper elevation={6} style={{ marginTop: 16, padding: 16 }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Login" />
          <Tab label="Register" />
        </Tabs>
        {tabIndex === 0 && <LoginForm />}
        {tabIndex === 1 && <RegisterForm />}
      </Paper>
    </Container>
  );
};

export default SplashScreen;
