import React, { createContext, useState, useContext, useCallback } from "react";

const PropertiesContext = createContext();

export const PropertiesProvider = ({ children }) => {
  const [properties, setProperties] = useState([]);

  const updateProperties = useCallback((newProperties) => {
    setProperties(newProperties);
  }, []);

  return (
    <PropertiesContext.Provider value={{ properties, updateProperties }}>
      {children}
    </PropertiesContext.Provider>
  );
};

export const usePropertiesContext = () => useContext(PropertiesContext);
