/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
// import { parseLocation, ParsedAddress } from "parse-address";
import { IProperty, Result } from "@farlosoftware/common-types";
import { validateAddress } from "../utils/addressValidator";
import log from "../utils/logger";

// TODO: Create a common-lib or common-utils for functions like isValidRedfinUrl,
// which is currently defined twice: once in the node app and once here.
function isValidRedfinUrl(urlString: string): boolean {
  try {
    const url = new URL(urlString);
    const isHttpOrHttps = ["http:", "https:"].includes(url.protocol);
    const isRedfinUrl = url.href.startsWith("https://www.redfin.com/");

    return isHttpOrHttps && isRedfinUrl;
  } catch (error) {
    return false;
  }
}

function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d.getTime());
}

function parseDate(date: string | Date) {
  if (typeof date === "string") {
    const parsedDate = new Date(date);
    if (isValidDate(parsedDate)) {
      return parsedDate;
    }
  } else if (isValidDate(date)) {
    return date;
  }
  return null;
}

export function validateProperty(property: IProperty): boolean {
  if (!property) return false;

  // Validate string fields are not empty
  if (!property.userId || property.userId.length === 0) return false;
  if (!property.name || property.name.length === 0) return false;
  if (!property.reiName || property.reiName.length === 0) return false;
  if (!property.address) return false;

  log.debug(
    "onboarding",
    "validateProperty: property.address: ",
    property.address,
  );
  const result = validateAddress(property.address);
  if (!result.success) {
    log.debug("onboarding", "Failed to validate address: ", property.address);
    return false;
  }

  if (!property.date) return false;

  const validDate = parseDate(property.date);
  if (!validDate) {
    log.debug(
      "onboarding",
      `${property.name}: failed date check, date: ${property.date}`,
    );
    return false;
  }

  if (property.salesPrice === undefined || property.salesPrice <= 0)
    return false;

  // redfin URL resolution is deferred in the backend. i.e. It won't be
  // complete when the PUT returns. So don't check for it.
  // if(
  //   !property.redfinUrl ||
  //   property.redfinUrl.length === 0 ||
  //   !isValidRedfinUrl(property.redfinUrl)
  // )
  //   return false;

  return true;
}

export function validateProperties(properties: IProperty[]): Result<number> {
  let validCount = 0;
  let invalidCount = 0;

  if (!properties || properties.length < 1)
    return {
      success: false,
      error: "No properties provided",
    };

  properties.forEach((property) => {
    if (validateProperty(property)) {
      validCount++;
    } else {
      invalidCount++;
    }
  });

  if (invalidCount === 0 && validCount === properties.length)
    return { success: true, value: validCount };

  return {
    success: false,
    error: `invalid properties: ${invalidCount}, valid properties: ${validCount}`,
  };
}
