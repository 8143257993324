import React, { createContext, useEffect, useState, useContext } from "react";
import { io, Socket } from "socket.io-client";
import config from "../utils/config";
import auth from "../services/authService";
import {
  JobType,
  JobCompletedData,
  JobFailedData,
  PortfolioMonthlyReport,
  IEstimate,
} from "@farlosoftware/common-types";
import { formatData } from "../utils/logger";

// Define the context types
interface WebSocketContextType {
  socket: Socket | null;
  jobCompleted: (data: JobCompletedData) => void;
  jobFailed: (data: JobFailedData) => void;
  portfolioReports: PortfolioMonthlyReport[] | null;
  estimate: IEstimate | null;
  isConnected: boolean;
  error: string | null;
}

// Create the context
const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined,
);

// WebSocket provider component
export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [portfolioReports, setPortfolioReports] = useState<
    PortfolioMonthlyReport[] | null
  >(null);
  const [estimate, setEstimate] = useState<IEstimate | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log("WebSocketProvider initial useEffect() running");
    const token = auth.getJwt();
    const socketUrl = config.socketUrl;
    console.log("socketUrl: ", socketUrl);
    const socket = io(socketUrl, {
      path: "/ws",
      query: {
        token: token,
      },
      reconnection: true, // Enable reconnection
      reconnectionAttempts: Infinity, // Try to reconnect indefinitely
      reconnectionDelay: 1000, // Start with a 1 second delay
      reconnectionDelayMax: 5000, // Max delay between reconnections
      timeout: 20000, // Before connect_error and connect_timeout are emitted
    });

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
      setIsConnected(true);
      setError(null);
    });

    socket.on("jobCompleted", (data: JobCompletedData) => {
      handleJobCompleted(data);
    });

    socket.on("jobFailed", (data: JobFailedData) => {
      handleJobFailed(data);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
      setIsConnected(false);
    });
    socket.on("connect_error", (err) => {
      console.error("WebSocket connection error:", err);
      setError("WebSocket connection error. Retrying...");
    });

    socket.on("reconnect", (attemptNumber) => {
      console.log(`WebSocket reconnected after ${attemptNumber} attempts`);
      setIsConnected(true);
      setError(null);
    });

    socket.on("reconnect_attempt", (attemptNumber) => {
      console.log(`WebSocket reconnect attempt ${attemptNumber}`);
      setError("Attempting to reconnect...");
    });

    socket.on("reconnect_failed", () => {
      console.error("WebSocket reconnection failed");
      setError("WebSocket reconnection failed. Please check your connection.");
    });

    setSocket(socket);

    return () => {
      socket.close();
      console.log("WebSocket connection closed");
    };
  }, []);

  const handleJobCompleted = (data: JobCompletedData) => {
    console.log(`Handling job completed: ${data.jobType}`);
    switch (data.jobType) {
      case JobType.PortfolioMonthlyReport:
        const portfolioReports: PortfolioMonthlyReport[] = data.result;
        setPortfolioReports(portfolioReports);
        console.log(
          "handleJobCompleted: PortfolioMonthlyReport Array Length: ",
          portfolioReports.length,
        );
        // Update your UI to display the PortfolioMonthlyReport array
        break;
      case JobType.PropertyValuationEstimate:
        const estimate: IEstimate = data.result;
        setEstimate(estimate);
        console.log(`handleJobCompleted: data: ${formatData(data)}`);
        console.log(
          `handleJobCompleted: PropertyValuationEstimate: ${estimate}`,
        );
        // Update your UI to display the PropertyValuationEstimate
        break;
      default:
        console.warn("Received unknown job type:", data.jobType);
        break;
    }
  };

  const handleJobFailed = (data: JobFailedData) => {
    console.log("Handling job failed:", data.error);
    // Update your UI to show the error
  };

  const contextValue = {
    socket,
    jobCompleted: handleJobCompleted,
    jobFailed: handleJobFailed,
    portfolioReports,
    estimate,
    isConnected,
    error,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom hook to use the WebSocket context
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
