/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import React, { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import Button from "@mui/material/Button";
import { useLoaderData } from "react-router-dom";
import {
  getProperties,
  getPropertyEstimate,
} from "../services/propertyService";
import { getLedgerYears } from "../services/transaction";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { runReport } from "../services/reportService";
import DataTable from "../components/muiReport";
import { useTheme } from "@mui/material/styles";
import { formatData } from "../utils/logger";
import { iEstimateToCSVString } from "../utils/estimateHelper";

export async function loader({ request, params }) {
  const result = await getProperties();
  if (!result.success || !result.value) {
    console.log("Warning: user not found");
    return { properties: null, user: null };
  }
  const properties = result.value;
  const ledgerYears = await getLedgerYears();
  return { properties, ledgerYears };
}

export async function action({ request, params }) {
  const formData = await request.formData();
  const obj = Object.fromEntries(formData);
  console.log("obj: ", obj);
  // tax.propertyId = params.propertyId;
  // const response = await saveTax(tax);
  // return redirect(`/properties/${params.propertyId}/taxes`);
  return null;
}

export default function Reports() {
  const { properties, ledgerYears } = useLoaderData();
  const [propsChecked, setPropsChecked] = useState(initPropsChecked);
  const [yearsChecked, setYearsChecked] = useState(initYearsChecked);
  const [combinedChecked, setCombinedChecked] = useState({ checked: false });
  const [reports, setReports] = useState(null);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const theme = useTheme();

  function initPropsChecked() {
    return properties.map((property) => ({
      label: property.name,
      value: property._id,
      checked: false,
    }));
  }

  function initYearsChecked() {
    return ledgerYears.map((year) => ({
      label: year.toString(),
      value: year,
      checked: false,
    }));
  }

  useEffect(() => {
    // Must have at least one property and one year checked
    setEnableSubmit(
      propsChecked.some((item) => item.checked) &&
        yearsChecked.some((item) => item.checked),
    );
  }, [propsChecked, yearsChecked]);

  // console.log(propertiesSelection);

  function handleChange(propChecked) {
    // console.log("propertySelection: ", propertySelection);
    setPropsChecked(
      propsChecked.map((prop) => {
        if (prop.value === propChecked.value) return propChecked;
        else return prop;
      }),
    );
  }

  function handleYearChange(yearChecked) {
    // console.log("propertySelection: ", propertySelection);
    setYearsChecked(
      yearsChecked.map((year) => {
        if (year.value === yearChecked.value) return yearChecked;
        else return year;
      }),
    );
  }

  function handleCombinedChange(combinedChange) {
    setCombinedChecked(combinedChange);
    console.log("combinedChange: ", combinedChange);
  }

  async function handleSubmit() {
    // console.log(propsChecked);
    // console.log(yearsChecked);
    const reportProps = propsChecked.filter((prop) => prop.checked);
    // const result = reportProps.map(({ value }) => value);
    // console.log("result: ", result);

    const reportYears = yearsChecked.filter((year) => year.checked);

    // console.log(reportProps);
    // console.log(reportYears);

    console.log("combinedChecked: ", combinedChecked);

    console.log("reportProps: ", reportProps);
    console.log("reportYears: ", reportYears);
    const response = await runReport(
      reportProps,
      reportYears,
      combinedChecked.checked,
    );
    // console.log("response: ", response);
    console.log("response.data: ", response.data);
    setReports(response.data);
  }

  async function handleValuationReportClick() {
    // Uncomment if you want to do redfinURL fetch testing
    // Validate fetching the redfin URLs
    // await validateRedfinUrlFetching();

    // Prepare the output with the header
    let csvOutput =
      "Property Name; Date; Estimate; Realtor Estimate; Redfin Estimate; Zillow Estimate; Realtor Date; Redfin Date; Zillow Date; Valid Start Date; Valid End Date\n";
    // Get today's date in YYYY.MM.DD format
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0].replace(/-/g, ".");

    try {
      for (const property of properties) {
        const result = await getPropertyEstimate(property._id);
        console.log("result: ", formatData(result));
        if (!result.success || !result.value) {
          console.log(
            `WARNING: failed to get valid estimateResponse for ${property.name}, error: ${result.error}`,
          );
          continue;
        }
        const { value: estimate } = result;
        console.log("estimate: ", estimate);
        const line = property.name + ";" + iEstimateToCSVString(estimate);
        console.log(line, formatData(estimate));
        csvOutput += line;
      }

      const fileName = `Estimates-${formattedDate}.csv`;

      // Create a blob from the CSV data
      const blob = new Blob([csvOutput], { type: "text/csv" });

      // Create a link element
      const link = document.createElement("a");
      // Set the download attribute with the desired file name
      link.download = fileName;
      // Create a URL for the blob and set it as the href attribute
      link.href = window.URL.createObjectURL(blob);
      // Append the link to the body
      document.body.appendChild(link);
      // Programmatically click the link to trigger the download
      link.click();
      // Remove the link from the document
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Form
          method="post"
          id="tax-form"
          className="needs-validation"
          noValidate
        >
          <FormControl
            sx={{ mt: 3, mb: 1, ml: 3, mr: 3 }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel component="legend">Properties in Report</FormLabel>
            <FormGroup
              sx={{
                position: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {propsChecked.map((prop) => (
                <FormControlLabel
                  key={prop.value}
                  control={
                    <Checkbox
                      checked={prop.checked}
                      onChange={(e) => {
                        handleChange({
                          ...prop,
                          checked: e.target.checked,
                        });
                      }}
                      name={prop.label}
                    />
                  }
                  label={prop.label}
                />
              ))}
            </FormGroup>
            <FormLabel component="legend">Years in Report</FormLabel>
            <FormGroup
              sx={{
                position: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {yearsChecked.map((year) => (
                <FormControlLabel
                  key={year.value}
                  control={
                    <Checkbox
                      checked={year.checked}
                      onChange={(e) => {
                        handleYearChange({
                          ...year,
                          checked: e.target.checked,
                        });
                      }}
                      name={year.label}
                    />
                  }
                  label={year.label}
                />
              ))}
            </FormGroup>
            <FormLabel component="legend">Combined report</FormLabel>
            <FormGroup
              sx={{
                position: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                key="combined"
                control={
                  <Checkbox
                    checked={combinedChecked.checked}
                    onChange={(e) => {
                      handleCombinedChange({
                        ...combinedChecked,
                        checked: e.target.checked,
                      });
                    }}
                    name="Combined Report"
                  />
                }
                label="Combined Report"
              />
            </FormGroup>
          </FormControl>
        </Form>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!enableSubmit}
          sx={{
            ml: theme.spacing(3),
            mt: theme.spacing(0),
            mb: theme.spacing(3),
          }}
        >
          Submit
        </Button>
        <Button
          onClick={handleValuationReportClick}
          variant="contained"
          sx={{
            ml: theme.spacing(3),
            mt: theme.spacing(0),
            mb: theme.spacing(3),
          }}
        >
          Download Valuation Report
        </Button>
      </Box>
      {reports && (
        <Box sx={{ ml: theme.spacing(3), mr: theme.spacing(3) }}>
          <DataTable data={reports} />
        </Box>
      )}
    </>
  );
}
