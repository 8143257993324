/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import http from "./httpService";
import devConfig from "../config/devConfig.json";
import prodConfig from "../config/prodConfig.json";
const config =
  process.env.REACT_APP_CONFIG_FILE === "prodConfig.json"
    ? prodConfig
    : devConfig;

const apiUrl = config.apiUrl;
const apiEndpoint = apiUrl + "/taxes";
const uploadTaxesURL = apiEndpoint + "/uploadTaxes";

function taxesUrl(id) {
  return `${apiEndpoint}/${id}`;
}

// export async function getTaxLedger() {
//   const { data: taxes } = await http.get(apiEndpoint);
//   return taxes;
// }

// getTaxes: get all Tax records. Populate the propertyId to the full IProperty
// object
export async function getTaxes() {
  return http.get(apiEndpoint);
}

export async function getTax(taxId) {
  return http.get(taxesUrl(taxId));
}

export async function saveTax(tax) {
  if (tax._id) {
    const body = { ...tax };
    console.log("body: ", body);

    delete body._id; // _id not allowed in the json object
    return await http.put(taxesUrl(tax._id), body);
  }
  return await http.post(apiEndpoint, tax);
}

export async function uploadTaxes(taxFile) {
  let formData = new FormData();
  formData.append("file", taxFile);
  console.log("uploadTaxesURL: ", uploadTaxesURL);
  const response = await http.post(uploadTaxesURL, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  console.log("response: ", response);
  return response;
}
