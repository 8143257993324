// DrawerComponent.jsx or wherever you implement the drawer
import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { NavLink } from "react-router-dom";
import { useDrawerContext } from "../components/drawerContext";

const DrawerComponent = ({ user, properties }) => {
  const { drawerOpen, handleDrawerToggle } = useDrawerContext();
  const [openProperties, setOpenProperties] = useState(false);

  const handlePropertiesClick = () => {
    setOpenProperties(!openProperties);
  };

  return (
    <Drawer
      open={drawerOpen}
      onClose={handleDrawerToggle}
      // Other Drawer props...
    >
      <List>
        <ListItemButton
          onClick={handleDrawerToggle}
          component={NavLink}
          to="/dashboard"
        >
          <ListItemText primary="Home" />
        </ListItemButton>

        <ListItemButton onClick={handlePropertiesClick}>
          <ListItemText primary="Properties" />
          {openProperties ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={openProperties} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {properties.map((property) => (
              <ListItemButton
                key={property._id}
                component={NavLink}
                to={`/properties/${property._id}`}
                onClick={handleDrawerToggle}
                sx={{ pl: 4 }} // Add indentation for nested items
              >
                <ListItemText primary={property.name} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>

        <ListItemButton
          onClick={handleDrawerToggle}
          component={NavLink}
          to="/ledger"
        >
          <ListItemText primary="Ledger" />
        </ListItemButton>
        <ListItemButton
          onClick={handleDrawerToggle}
          component={NavLink}
          to="/taxes"
        >
          <ListItemText primary="Taxes" />
        </ListItemButton>
        <ListItemButton
          onClick={handleDrawerToggle}
          component={NavLink}
          to="/reports"
        >
          <ListItemText primary="Reports" />
        </ListItemButton>
        {!user && (
          <>
            <ListItemButton
              onClick={handleDrawerToggle}
              component={NavLink}
              to="/register"
            >
              <ListItemText primary="Register" />
            </ListItemButton>
            <ListItemButton
              onClick={handleDrawerToggle}
              component={NavLink}
              to="/login"
            >
              <ListItemText primary="Login" />
            </ListItemButton>
          </>
        )}
        {user && (
          <ListItemButton
            onClick={handleDrawerToggle}
            component={NavLink}
            to="/logout"
          >
            <ListItemText primary="Logout" />
          </ListItemButton>
        )}
      </List>
    </Drawer>
  );
};

export default DrawerComponent;
