/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Form, Link as RouterLink, useParams } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
  Button,
} from "@mui/material";
import {
  getProperty,
  getPropertyEstimate,
  getPropertyTaxes,
} from "../services/propertyService";
import { useWebSocket } from "../contexts/webSocketContext";
// import CldPhoto from "../components/cldPhoto";
import { BeatLoader } from "react-spinners";
import { numberToUSD } from "../utils/formatting";
import { useTheme } from "@mui/material/styles";
import { getAddressString } from "@farlosoftware/common-types";
import { formatUTCDate } from "../utils/dateUtils";
import _, { debounce } from "lodash";

function TableRowComponent({ label, content, sx }) {
  return (
    <TableRow>
      <TableCell sx={{ ...sx, width: "50%" }} component="th">
        {label}
      </TableCell>
      <TableCell sx={{ ...sx, width: "50%" }}>{content}</TableCell>
    </TableRow>
  );
}

function LedgerTableRowLink({ label, link }) {
  return (
    <TableRow>
      <TableCell component="th">
        <RouterLink to={link}>{label}</RouterLink>
      </TableCell>
      <TableCell>Ledger</TableCell>
    </TableRow>
  );
}

function TaxTableRowLink({ label, link, taxes }) {
  const limitedTaxes = taxes.slice(0, 3); // Get the first 3 tax payments

  return (
    <TableRow>
      <TableCell component="th" style={{ width: "50%" }}>
        <RouterLink to={link}>{label}</RouterLink>
      </TableCell>
      <TableCell colSpan={2} style={{ padding: 0 }}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {limitedTaxes.map((tax) => (
                <TableRow
                  key={tax._id}
                  sx={{ "& > td": { borderBottom: "none" } }}
                >
                  <TableCell>{formatUTCDate(tax.paymentDate)}</TableCell>
                  <TableCell>{numberToUSD(tax.amount)}</TableCell>
                  <TableCell>{tax.taxingAuthority}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableCell>
    </TableRow>
  );
}

function TaxTableRowLinkold({ label, link, taxes }) {
  const limitedTaxes = taxes.slice(0, 3); // Get the first 5 tax payments
  return (
    <TableRow>
      <TableCell component="th">
        <RouterLink to={link}>{label}</RouterLink>
      </TableCell>
      <TableCell>
        {limitedTaxes.map((tax, i) => (
          <div key={tax._id}>
            {new Date(tax.paymentDate).toLocaleDateString()}:&nbsp;
            {numberToUSD(tax.amount)}
            {tax.taxingAuthority}
          </div>
        ))}
      </TableCell>
    </TableRow>
  );
}

export default function Property() {
  const { propertyId } = useParams();
  console.log("propertyId: ", propertyId);
  const [property, setProperty] = useState(null);
  const [estimate, setEstimate] = useState(null);
  const [estimateError, setEstimateError] = useState(null);
  const [taxes, setTaxes] = useState(null);
  const [loadingProperty, setLoadingProperty] = useState(true);
  const [loadingEstimate, setLoadingEstimate] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const { estimate: webSocketEstimate } = useWebSocket();

  const fetchData = useCallback(
    debounce(async (id) => {
      setLoadingProperty(true);
      setLoadingEstimate(true);
      setProperty(null);
      setTaxes(null);
      setEstimate(null);
      setError(null);

      try {
        const propertyResponse = await getProperty(id);
        const taxesResponse = await getPropertyTaxes(id);

        setProperty(propertyResponse);
        setTaxes(taxesResponse);
      } catch (err) {
        setError("Failed to fetch data");
      }
      setLoadingProperty(false);
    }, 300),
    [],
  );

  useEffect(() => {
    fetchData(propertyId);
  }, [propertyId, fetchData]);

  const fetchEstimate = useCallback(
    debounce(async (id) => {
      setLoadingEstimate(true);
      setEstimate(null);
      setError(null);

      try {
        const result = await getPropertyEstimate(id);
        if (result.success && result.value) {
          const { value: theEstimate } = result;
          setEstimate(theEstimate);
          setEstimateError(null);
          setLoadingEstimate(false);
        } else {
          setEstimateError(result.error);
        }
      } catch (err) {
        setError("Failed to fetch estimate");
      }
    }, 300), // Debounce delay in milliseconds
    [],
  );

  useEffect(() => {
    fetchEstimate(propertyId);
  }, [propertyId, fetchEstimate]);

  const reloadEstimate = async () => {
    setLoadingEstimate(true);
    setEstimate(null);
    setError(null);

    try {
      const result = await getPropertyEstimate(propertyId);
      if (result.success && result.value) {
        const { value: theEstimate } = result;
        setEstimate(theEstimate);
        setEstimateError(null);
        setLoadingEstimate(false);
      } else {
        setEstimateError(result.error);
      }
    } catch (err) {
      setError("Failed to reload estimate");
    }
    setLoadingEstimate(false);
  };

  useEffect(() => {
    // Update local estimate state if the WebSocket estimate is for the current property
    if (
      webSocketEstimate &&
      webSocketEstimate.propertyId.toString() === propertyId
    ) {
      setEstimate(webSocketEstimate);
    }
  }, [webSocketEstimate, propertyId]);

  if (loadingProperty) {
    return <BeatLoader color={"#36D7B7"} loading={true} />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  console.log("taxes: ", taxes);

  const { name, date, salesPrice, notes, imageId } = property;
  const address = getAddressString(property.address);

  console.log("estimate: ", estimate);

  return (
    <Box
      sx={{
        width: "100%", // Default to full width on smaller screens
        [theme.breakpoints.up("md")]: {
          width: "30%", // On medium and larger screens, use 50% width
        },
        p: 2,
      }}
    >
      <Typography variant="h4">{name}</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "100%", ml: -1, pr: 2 }}>
          {/* <Table size="small" sx={{ "& th, & td": { py: 0.5, px: 0.5 } }}> */}
          <Table sx={{ /* ml: -1.5,*/ width: "100%", maxWidth: "100%" }}>
            <TableBody>
              <TableRowComponent
                label="Address"
                content={address}
                /* sx={{ whiteSpace: "nowrap" }} */
              />
              <TableRowComponent
                label="Purchase Date"
                content={date ? formatUTCDate(date) : ""}
              />
              <TableRowComponent
                label="Purchase Price"
                content={numberToUSD(salesPrice)}
              />
              {/* <TableRowComponent label="Redfin URL" content={redfinLink} /> */}
              <TableRowComponent
                label="Estimated Property Value"
                content={
                  estimate
                    ? numberToUSD(estimate.value)
                    : estimateError
                      ? estimateError
                      : "Unknown error"
                }
              />
              <TaxTableRowLink
                label="Tax Payments"
                link="taxes"
                taxes={taxes}
              />
              <LedgerTableRowLink label="Ledger" link="ledger" />
              {notes !== "" && (
                <TableRowComponent label="Notes" content={notes} />
              )}
            </TableBody>
          </Table>
        </Box>
        {/* <Box sx={{ width: "50%" }}> */}
        {/*   {imageId && <CldPhoto cloudName="dcgzpptzg" publicId={imageId} />} */}
        {/* </Box> */}
      </Box>
      <Form action="edit">
        <Button type="submit" variant="contained" sx={{ mt: 2 }}>
          Edit
        </Button>
      </Form>
    </Box>
  );
}
