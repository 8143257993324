/*
 * Copyright (C) 2024 Farlo Software, LLC
 * All rights reserved.
 */
export function numberToUSD(x: number | null | undefined): string {
  if (x === null || x === undefined) return "";

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  return formatter.format(x);
}

export function usdToNumber(
  usdString: string | number | null | undefined,
): number {
  if (typeof usdString === "number") {
    return isNaN(usdString) ? NaN : usdString;
  }

  if (typeof usdString !== "string") {
    return NaN;
  }

  // Remove non-numeric characters except for periods and commas
  const cleanedString = usdString.replace(/[^0-9.,]/g, "");

  // Replace commas with empty string and convert to a number
  const numericValue = parseFloat(cleanedString.replace(/,/g, ""));

  // Check if the result is a valid number
  if (isNaN(numericValue)) {
    console.error("Invalid dollar string:", usdString);
    return NaN;
  }

  return numericValue;
}

export function fractionToPercent(fraction: number): string {
  return `${(fraction * 100).toFixed(2)}%`;
}

// Function to format estimate if it's a number, otherwise leave it as is
export function formatEstimate(estimate: string | number): string | number {
  // Check if estimate is a number by trying to parse it
  const parsedEstimate = Number(estimate);

  // If the parsed estimate is a number (not NaN) and the original estimate is not already formatted as a string
  if (!isNaN(parsedEstimate) && typeof estimate === "number") {
    // Round up to the nearest integer
    const roundedEstimate = Math.ceil(parsedEstimate);
    // Format it as currency
    return `$${new Intl.NumberFormat("en-US").format(roundedEstimate)}`;
  } else {
    // If it's not a number, just return it as is
    return estimate;
  }
}
