import React from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import TimerIcon from "@mui/icons-material/Timer";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
/* import MoneyOffIcon from "@mui/icons-material/MoneyOff"; */
/* import MoneyIcon from "@mui/icons-material/Money"; */
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useOutletContext } from "react-router-dom";
import { PropertyCountByState } from "src/utils/propertiesUtils";
import PerfChart from "../routes/perfChart";

interface DashboardProps {
  numberOfProperties: number;
  averageOwnershipTime: number;
  portfolioPurchasePrice: number;
  totalRentCollected: number;
  totalExpenses: number;
  countByState: PropertyCountByState;
}

const Dashboard: React.FC = () => {
  const context = useOutletContext<DashboardProps>();
  console.log("Dashboard context: ", context);
  if (!context) {
    return <div>Loading...</div>;
  }
  const {
    numberOfProperties,
    averageOwnershipTime,
    portfolioPurchasePrice,
    totalRentCollected,
    totalExpenses,
    countByState,
  } = context;

  // Sort the states by the number of properties in descending order
  const sortedStates = Object.entries(countByState).sort((a, b) => b[1] - a[1]);

  return (
    <div className="dashboard">
      <div className="dashboard-stats">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              style={{ padding: "16px", display: "flex", alignItems: "center" }}
            >
              <HomeIcon style={{ marginRight: "16px" }} />
              <div>
                <Typography variant="h6">
                  Number of Rental Properties
                </Typography>
                <Typography variant="h4">{numberOfProperties}</Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              style={{ padding: "16px", display: "flex", alignItems: "center" }}
            >
              <TimerIcon style={{ marginRight: "16px" }} />
              <div>
                <Typography variant="h6">Average Time of Ownership</Typography>
                <Typography variant="h4">
                  {averageOwnershipTime} years
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              style={{ padding: "16px", display: "flex", alignItems: "center" }}
            >
              <AttachMoneyIcon style={{ marginRight: "16px" }} />
              <div>
                <Typography variant="h6">Portfolio Purchase Price</Typography>
                <Typography variant="h4">
                  ${portfolioPurchasePrice.toLocaleString()}
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              style={{
                padding: "16px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box display="flex" alignItems="center" marginBottom="16px">
                <LocationCityIcon style={{ marginRight: "16px" }} />
                <Typography variant="h6">Properties by State</Typography>
              </Box>
              <Box display="flex" flexWrap="wrap">
                {sortedStates.map(([state, count]) => (
                  <Paper
                    key={state}
                    elevation={1}
                    style={{ margin: "8px", padding: "8px", minWidth: "100px" }}
                  >
                    <Typography variant="body1" align="center">
                      {state}
                    </Typography>
                    <Typography variant="h6" align="center">
                      {count}
                    </Typography>
                  </Paper>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div className="dashboard-chart">
        <PerfChart />
      </div>
    </div>
  );
};

export default Dashboard;
