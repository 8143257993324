/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import React, { useState, useEffect } from "react";
import {
  useLoaderData,
  useNavigate,
  useNavigation,
  useLocation,
  useParams,
} from "react-router-dom";
import { TextField, Button, Grid, Box, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
// import ImageUpload from "../components/imageUpload";
// import { Cloudinary } from "@cloudinary/url-gen";
import { BeatLoader } from "react-spinners";
import { getProperty, saveProperty } from "../services/propertyService";
import { usdToNumber } from "../utils/formatting";
import { usePropertiesContext } from "../contexts/propertiesContext";
import { getAddressString, parseAddress } from "@farlosoftware/common-types";
import { utcDateStringToUsDateString } from "../utils/dateUtils";
import { useSavePropertyContext } from "../contexts/savePropertyContext";
import { formatData } from "../utils/logger";

async function updateProperty(originalProperty, updates, iAddress) {
  // console.log("id:", id);
  // console.log("updates: ", updates);
  // console.log("updates.salesPrice = ", updates.salesPrice);
  updates.salesPrice = usdToNumber(updates.salesPrice);

  // console.log("updateProperty: about to getProperty id");
  // const property = await getProperty(id);
  // console.log("updateProperty: got property id");
  // console.log("property: ", property);
  const property = { ...originalProperty };

  // Destructure the address field out of the updates object and capture the rest of the updates in a new variable
  const { address, ...updatesWithoutAddress } = updates;
  Object.assign(property, updatesWithoutAddress);
  property.address = iAddress;

  return await saveProperty(property);
}

// NOTE - loader NOT used in onboarding!!! It was causing tons of
// problems. Using propertiesContext in onboarding.
export async function loader({ request, params }) {
  // console.log("loader: params.propertyId: ", params.propertyId);
  const property = await getProperty(params.propertyId);
  return { property };
}

export default function EditProperty() {
  const { setSavePropertyCallback } = useSavePropertyContext();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [dateError, setDateError] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const { properties, updateProperties } = usePropertiesContext();
  const loaderData = useLoaderData(); // We don't use loaderData in onboarding
  const { propertyId } = useParams();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  // Get the property from propertiesContext ("/onboarding") or
  // useLoaderData("/properties")
  let property;
  const inOnboarding = location.pathname.includes("onboarding");
  if (inOnboarding)
    property = properties.find((theProperty) => theProperty._id === propertyId);
  else property = loaderData.property;

  const date =
    property && property.date ? utcDateStringToUsDateString(property.date) : "";

  // Define state for form fields
  const [formData, setFormData] = useState({
    name: property?.name || "",
    address: property.address ? getAddressString(property.address) : "",
    salesPrice: property?.salesPrice || "",
    date: date || "",
  });

  useEffect(() => {
    if (formData.name !== property.name) {
      console.log("names don't match");
      setIsSaved(false);
    } else if (formData.address !== getAddressString(property.address)) {
      console.log("addresses don't match");
      setIsSaved(false);
    } else if (formData.date !== date) {
      console.log("dates don't match");
      console.log("  formData.date: ", formData.date);
      console.log("  property.date: ", date);
      setIsSaved(false);
    } else if (formData.salesPrice !== property.salesPrice) {
      console.log("salesPrices don't match");
      setIsSaved(false);
    } else setIsSaved(true);
  }, [formData, property]);

  function validateField(name, value) {
    if (name === "name") {
      const isValidName =
        /^[a-zA-Z0-9 ]*$/.test(value) && value.trim().length > 0;
      return isValidName
        ? null
        : "Special characters are not allowed (e.g. $%!)";
    } //
    else if (name === "address") {
      const result = parseAddress(value);
      console.log("isValidAddress result: ", result);
      return result.success ? null : result.error;
    } //
    else if (name === "salesPrice") {
      if (typeof value === "number") return null; // already a number. All good.
      const strippedValue =
        typeof value === "string" ? value.replace(/[$,]/g, "") : "";
      const isValidPrice =
        !isNaN(parseInt(strippedValue)) && isFinite(strippedValue);
      return isValidPrice
        ? null
        : "Invalid price. Acceptable formats: $55,000 or 55000";
    } //
    else if (name === "date") {
      const isValidDate = !isNaN(Date.parse(value));
      return isValidDate ? null : "Invalid date.";
    }

    return null; // For unknown fields, return no error
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Perform validation if the changed field is 'address'
    if (name === "address") {
      const error = validateField(name, value);
      setAddressError(error);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);

    // Set error state based on the field name
    if (name === "name") setNameError(error);
    else if (name === "address") setAddressError(error);
    else if (name === "salesPrice") setPriceError(error);
    else if (name === "date") setDateError(error);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    let errors = {
      name: validateField("name", formData.name),
      address: validateField("address", formData.address),
      salesPrice: validateField("salesPrice", formData.salesPrice),
      date: validateField("date", formData.date),
    };

    let hasErrors = false;
    Object.entries(errors).forEach(([fieldName, errorMessage]) => {
      if (errorMessage) {
        if (fieldName === "name") setNameError(errorMessage);
        else if (fieldName === "address") setAddressError(errorMessage);
        else if (fieldName === "salesPrice") setPriceError(errorMessage);
        else if (fieldName === "date") setDateError(errorMessage);
        hasErrors = true;
      }
    });
    if (hasErrors) {
      console.log("Aborting form submission due to field errors");
      return;
    }

    const result = parseAddress(formData.address);
    if (!result.success || !result.value) {
      console.log("Aborting form submission due to parseAddress failure");
      return;
    }
    const iAddr = result.value;

    // console.log("About to set isFormSubmitted to true");
    setIsFormSubmitted(true);
    // console.log("Set isFormSubmitted to true");

    // Call updateProperty and handle response
    const response = await updateProperty(property, formData, iAddr);
    console.log("updateProperty response: ", formatData(response));
    // console.log("editProperty: updateProperty() is complete");

    if (response instanceof Error) {
      console.error("Property update failed:", response.message);
    } else {
      // Find the index of the property that was updated
      const propertyIndex = properties.findIndex(
        (property) => property._id === propertyId,
      );

      if (propertyIndex !== -1) {
        // Create a copy of the updated property

        // Destructure the address field out of the updates object and capture the rest of the updates in a new variable
        const { address, ...updatesWithoutAddress } = formData;

        const updatedProperty = {
          ...properties[propertyIndex],
          ...updatesWithoutAddress,
        };
        updatedProperty.address = iAddr;

        // Update the properties array with the updated property
        const updatedProperties = [...properties];
        updatedProperties[propertyIndex] = updatedProperty;

        // Update the context with the new properties array
        updateProperties(updatedProperties);
      }
    }
    setSubmissionComplete(true);
  };

  useEffect(() => {
    setSavePropertyCallback(() => handleSubmit);
    return () => setSavePropertyCallback(null); // Cleanup on unmount
  }, [formData]);

  useEffect(() => {
    // console.log("useEffect: ");
    // console.log("  navigation.state: ", navigation.state);
    // console.log("  navigate: ", navigate);
    if (isFormSubmitted && submissionComplete && navigation.state === "idle") {
      // Perform navigation after form submission is complete
      if (location.pathname.includes("onboarding")) navigate("/onboarding");
      else {
        const path = `/properties/${propertyId}`;
        console.log("  navigating to path: ", path);
        navigate(path);
      }
      setIsFormSubmitted(false); // Reset form submission state
      setSubmissionComplete(false);
    }
  }, [navigation.state, navigate, isFormSubmitted, submissionComplete]);

  // console.log("propertyId from useParams: ", propertyId);
  // if (property) console.log("property._id: ", property._id);

  const enableEdits = navigation.state === "idle";
  const buttonText =
    navigation.state === "submitting"
      ? "Saving..."
      : navigation.state === "loading"
        ? "Saved!"
        : "Save";
  // const { data: propertyReportData } = propertyReport;
  // Image stuff
  // const cld = new Cloudinary({
  //   cloud: {
  //     cloud_name: "dcgzpptzg", //Your cloud name
  //     upload_preset: "cloudinary-test-upload-preset", //Create an unsigned upload preset and update this
  //   },
  // });

  // const onImageUploadHandler = async (publicId) => {
  //   if (!property) {
  //     console.log("Error: onImageUploadHandler with null property");
  //     return;
  //   }
  //   console.log("publicId: ", publicId);
  //   property.imageId = publicId;
  //   await saveProperty(property);
  //   navigate(-1);
  // };

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth={false} sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ maxWidth: "600px" }}>
              <TextField
                key={property ? property._id : "new-property"}
                label="Nickname"
                type="text"
                name="name"
                placeholder="Nickname for property"
                required
                fullWidth
                disabled={!enableEdits}
                value={formData.name}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!nameError} // Boolean value indicating if there is an error
                helperText={nameError} // The error message
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ maxWidth: "600px", textAlign: "left" }}>
            <Box sx={{ maxWidth: "600px" }}>
              <TextField
                key={property ? property._id : "new-property"}
                label="Address"
                type="text"
                name="address"
                placeholder="11 Wall Street, New York, NY"
                required
                fullWidth
                disabled={!enableEdits}
                value={formData.address}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!addressError} // Boolean value indicating if there is an error
                helperText={addressError} // The error message
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ maxWidth: "600px" }}>
              <TextField
                key={property ? property._id : "new-property"}
                label="Purchase Price"
                type="text"
                name="salesPrice"
                placeholder="0"
                required
                fullWidth
                disabled={!enableEdits}
                value={formData.salesPrice}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!priceError} // Boolean value indicating if there is an error
                helperText={priceError} // The error message
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ maxWidth: "600px" }}>
              <TextField
                key={property ? property._id : "new-property"}
                label="Purchase Date"
                type="date"
                name="date"
                required
                fullWidth
                disabled={!enableEdits}
                value={formData.date}
                onBlur={handleBlur}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!dateError} // Boolean value indicating if there is an error
                helperText={dateError} // The error message
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12}> */}
          {/*   <Box sx={{ maxWidth: "600px" }}> */}
          {/*     <TextField */}
          {/*       key={property ? property._id : "new-property"} */}
          {/*       label="Redfin URL" */}
          {/*       type="url" */}
          {/*       name="redfinUrl" */}
          {/*       placeholder="" */}
          {/*       required */}
          {/*       fullWidth */}
          {/*       disabled={!enableEdits} */}
          {/*       defaultValue={property && property.redfinUrl} */}
          {/*     /> */}
          {/*   </Box> */}
          {/* </Grid> */}
          <Grid item xs={12}>
            <Box
              sx={{ maxWidth: "600px", display: "flex", alignItems: "center" }}
            >
              <Button
                type="submit"
                variant="contained"
                disabled={
                  isSaved ||
                  !enableEdits ||
                  !!nameError ||
                  !!addressError ||
                  !!priceError ||
                  !!dateError
                }
                sx={{ mr: 4 }}
              >
                {buttonText}
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                disabled={!enableEdits}
                sx={{ mr: 4 }}
              >
                Cancel
              </Button>
              {isSaved && (
                <span
                  style={{
                    display: "inline-flex",
                    marginLeft: "0",
                    alignItems: "center",
                  }}
                >
                  <CloudDoneIcon
                    style={{ color: theme.palette.primary.main }}
                    fontSize="large"
                  />
                  <span
                    style={{
                      marginLeft: "6px",
                      verticalAlign: "middle",
                      color: theme.palette.primary.main,
                      fontFamily: theme.typography.fontFamily,
                      textTransform: "uppercase", // Makes the text all-caps
                      marginTop: "4px", // Adjusts the text slightly upwards
                    }}
                  >
                    Saved
                  </span>
                </span>
              )}
              {/* {enableEdits && ( */}
              {/*   <ImageUpload */}
              {/*     cloud_name={cld.cloudinaryConfig.cloud.cloud_name} */}
              {/*     upload_preset={cld.cloudinaryConfig.cloud.upload_preset} */}
              {/*     onImageUpload={(publicId) => onImageUploadHandler(publicId)} */}
              {/*   /> */}
              {/* )} */}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ maxWidth: "600px" }}>
              {!enableEdits && <BeatLoader color={"#36D7B7"} loading={true} />}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
}
