/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import { Form, redirect, useNavigate } from "react-router-dom";
import { saveTax } from "../services/taxService";

export async function action({ request, params }) {
  const formData = await request.formData();
  const tax = Object.fromEntries(formData);
  tax.propertyId = params.propertyId;
  const response = await saveTax(tax);
  if (response !== 200) console.log("Failed to save tax record");
  return redirect(`/properties/${params.propertyId}/taxes`);
}

export default function AddTax() {
  const navigate = useNavigate();

  return (
    <>
      <Form method="post" id="tax-form" className="needs-validation" noValidate>
        <div className="form row">
          <div className="col-md-4 mb-3">
            <label>
              <span>Date Paid</span>
              <input
                type="date"
                className="form-control"
                name="paymentDate"
                placeholder="01/01/1970"
                required
                /* defaultValue={propertyData.name} */
              />
            </label>
            <label>
              <span>Tax Year</span>
              <input
                type="text"
                inputMode="numeric"
                className="form-control"
                name="taxYear"
                placeholder="1970"
                required
                /* defaultValue={propertyData.address} */
              />
            </label>
            <label>
              <span>Amount</span>
              <input
                type="text"
                inputMode="numeric"
                className="form-control"
                name="amount"
                placeholder="0"
                required
                /* defaultValue={propertyData.salesPrice} */
              />
            </label>
            <p>
              <button type="submit">Save</button>
              <button
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </button>
            </p>
          </div>
        </div>
      </Form>
    </>
  );
}
