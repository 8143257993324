import { usePropertiesContext } from "../contexts/propertiesContext";
import { uploadLedger } from "../services/transaction";
import { getProperties } from "../services/propertyService";

export const useUploadLedger = () => {
  const { updateProperties } = usePropertiesContext();

  const handleUpload = async (ledgerFile) => {
    try {
      console.log("handleUpload: about to uploadLedger: ");
      const response = await uploadLedger(ledgerFile);
      console.log("handleUpload: response: ", response);
      // Assuming response includes success status or updated properties directly
      if (response && response.status === 200) {
        // If the updated properties are not returned, you might need to fetch them
        const result = await getProperties();
        if (result && result.success && result.value) {
          updateProperties(result.value);
          console.log("handleUpload: updatedProperties with: ", result.value);
          // For simplicity, assuming response contains the updated properties
          // updateProperties(response.updatedProperties);
          return true; // success
        }
        console.log("handleUpload: getProperties() failed: ", result);
        return false;
      }
      return false; // You can return some status or message if needed
    } catch (error) {
      console.error("Error uploading ledger:", error);
      // Handle error, possibly returning it or setting an error state
      return false; // failure
    }
  };

  return handleUpload;
};
