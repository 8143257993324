/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import http from "./httpService";
import devConfig from "../config/devConfig.json";
import prodConfig from "../config/prodConfig.json";
import {
  IdString,
  IProperty,
  IEstimate,
  ITax,
  BaseReport,
  ITransaction,
  Result,
} from "@farlosoftware/common-types";
const config =
  process.env.REACT_APP_CONFIG_FILE === "prodConfig.json"
    ? prodConfig
    : devConfig;

const apiUrl = config.apiUrl;
const apiEndpoint = apiUrl + "/properties";

function propertiesUrl(id: IdString) {
  return `${apiEndpoint}/${id}`;
}

function propertiesReportUrl(id: IdString, start: string, end: string) {
  return `${apiEndpoint}/${id}/${start}-${end}`;
}

function propertiesEstimateUrl(id: IdString) {
  return `${apiEndpoint}/${id}/valuation`;
}

function propertiesTaxesUrl(id: IdString) {
  return `${apiEndpoint}/${id}/taxes`;
}

function propertiesLedgerUrl(id: IdString) {
  return `${apiEndpoint}/${id}/ledger`;
}

export async function getProperties() {
  try {
    const { data: properties } = await http.get(apiEndpoint);
    return { success: true, value: properties };
  } catch (error) {
    // User not found, probably a stale login
    console.log("Error: getProperties failed", error);
    return { success: false, error: "User not found" };
  }
}

export async function getProperty(id: IdString) {
  try {
    const response = await http.get(propertiesUrl(id));
    if (response.status === 200) {
      return response.data; // Assuming 'data' contains the property information
    } else {
      throw new Error(`HTTP error: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching property:", error);
    throw error; // Handle errors appropriately
  }
}

export async function saveProperty(property: IProperty) {
  if (property._id) {
    // Create a new object from the property, making all fields optional
    const body: Partial<IProperty> = { ...property };

    // Physically remove the '_id' field from the object
    delete body._id;

    return await http.put(propertiesUrl(property._id), body);
  }
  throw new Error("creating new properties is not supported");
}

export async function getPropertyReport(
  id: IdString,
  start: string,
  end: string,
): Promise<BaseReport> {
  const response = await http.get(propertiesReportUrl(id, start, end));
  return response.data;
}

export async function getPropertyEstimate(
  id: IdString,
): Promise<Result<IEstimate>> {
  const response = await http.get(propertiesEstimateUrl(id));
  return response.data;
}

export async function getPropertyTaxes(propertyId: IdString): Promise<ITax[]> {
  const response = await http.get(propertiesTaxesUrl(propertyId));
  return response.data;
}

export async function getPropertyLedger(
  propertyId: IdString,
): Promise<ITransaction[]> {
  const { data: ledger } = await http.get(propertiesLedgerUrl(propertyId));
  return ledger;
}
