/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import React, { useState, useEffect, useContext, useCallback } from "react";
import { ThemeContext } from "../components/themeComponent";
import {
  Outlet,
  useLoaderData,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getProperties } from "../services/propertyService";
import auth from "../services/authService";
import Navbar from "../components/navbar";
import DrawerComponent from "../components/drawerComponent";
import OnboardingContent from "../components/onboarding";
import { validateProperties } from "../services/propertyUtils";
import { usePropertiesContext } from "../contexts/propertiesContext";
import {
  calculateAverageOwnershipTime,
  calculatePortfolioPurchasePrice,
  calculateTotalRentCollected,
  calculateTotalExpenses,
  countPropertiesByState,
} from "../utils/propertiesUtils";

export default function Root() {
  // const { user, properties } = useLoaderData();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { darkMode, toggleTheme } = useContext(ThemeContext);
  const {
    properties: contextProperties,
    updateProperties: updateContextProperties,
  } = usePropertiesContext();

  useEffect(() => {
    async function fetchData() {
      const currentUser = await auth.getCurrentUser(navigate);
      setUser(currentUser);

      if (currentUser) {
        const result = await getProperties();
        if (result.success && result.value) {
          updateContextProperties(result.value);
        } else {
          console.log("Warning: failed to get properties. User not logged in?");
          updateContextProperties(null);
          return;
        }
      } else {
        console.log("Warning: currentUser is nullish");
        updateContextProperties(null);
      }

      setLoading(false);
    }

    fetchData();
  }, []);

  // Function to determine if onboarding is complete
  const isOnboardingComplete = () => {
    if (
      // Right after onboarding, sometimes, the stupid react-router loader
      // doesn't work. In that specific scenario contextProperties is correct,
      // so use it.
      !contextProperties ||
      contextProperties.length < 1
    ) {
      console.log("Onboarding Incomplete: properties: ", contextProperties);
      return false;
    }
    const result = validateProperties(contextProperties);
    console.log("validateProperties(properties) result: ", result);
    // const contextResult = validateProperties(contextProperties);
    // console.log("validateProperties(contextProperties): ", contextResult);
    // if (!result.success && contextResult.success) {
    //   console.log("ERROR! properties from db and context discrepancy!");
    //   return true;
    // }

    return result.success;
  };

  useEffect(() => {
    if (!loading) {
      const currentPath = location.pathname;
      const onboardingNeeded = !isOnboardingComplete();
      console.log("onboardingNeeded: ", onboardingNeeded);
      // Check user status and determine the target path
      let targetPath = currentPath;
      if (user) {
        if (onboardingNeeded && !currentPath.startsWith("/onboarding")) {
          console.log("Onboarding needed: navigating to /onboarding");
          targetPath = "/onboarding";
        } else if (
          !onboardingNeeded &&
          (currentPath === "/" || currentPath.startsWith("/splashScreen"))
        ) {
          targetPath = "/dashboard"; // Default post-login route for authenticated users
        }
      } else if (!currentPath.startsWith("/splashScreen")) {
        targetPath = "/splashScreen"; // Send to login page
      }

      // Navigate to the target path if it's different from the current path
      if (targetPath !== currentPath) {
        navigate(targetPath, { replace: true }); // Using 'replace' to avoid creating extra history entries
      }

      document.title = "LeaseLogic";
    }
  }, [
    loading,
    user,
    contextProperties,
    navigate,
    location.pathname,
    isOnboardingComplete,
  ]);

  const dashboardData =
    contextProperties && user
      ? {
          numberOfProperties: contextProperties?.length || 0,
          averageOwnershipTime:
            calculateAverageOwnershipTime(contextProperties) || 0,
          portfolioPurchasePrice:
            calculatePortfolioPurchasePrice(contextProperties) || 0,
          countByState: countPropertiesByState(contextProperties) || { "": 0 },
          totalRentCollected:
            calculateTotalRentCollected(contextProperties) || 0,
          totalExpenses: calculateTotalExpenses(contextProperties) || 0,
        }
      : null;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ToastContainer />
      {user && (
        <Navbar darkMode={darkMode} toggleTheme={toggleTheme} user={user} />
      )}
      {user && contextProperties && (
        <DrawerComponent user={user} properties={contextProperties} />
      )}
      <div className="container-fluid" style={{ marginTop: "20px" }}>
        <div className="row justify-content-start">
          <div className="col-12">
            <Outlet context={dashboardData} />
          </div>
        </div>
      </div>
    </>
  );
}
