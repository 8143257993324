/*
 * Copyright (C) 2023 Farlo Software, LLC
 * All rights reserved.
 */
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { numberToUSD, fractionToPercent } from "../utils/formatting";

const columns = [
  { field: "name", headerName: "Property", width: 135 },
  // { field: "reportPeriodDays", headerName: "Report Period (days)", width: 70 },
  { field: "year", headerName: "Year", width: 60 },
  {
    field: "revenue",
    headerName: "Revenue",
    width: 80,
    valueFormatter: (params) => numberToUSD(params.value),
  },
  {
    field: "ledgerExpenses",
    headerName: "Expenses (Ledger)",
    width: 80,
    valueFormatter: (params) => numberToUSD(params.value),
  },
  {
    field: "taxesPaidUSD",
    headerName: "Taxes",
    width: 80,
    valueFormatter: (params) => numberToUSD(-params.value),
  },
  {
    field: "expenses",
    headerName: "Gross Expenses",
    width: 80,
    valueFormatter: (params) => numberToUSD(params.value),
  },
  {
    field: "income",
    headerName: "Income",
    width: 80,
    valueFormatter: (params) => numberToUSD(params.value),
  },
  {
    field: "totalPricePaid",
    headerName: "Purchase Price",
    width: 120,
    valueFormatter: (params) => numberToUSD(params.value),
  },
  {
    field: "roi",
    headerName: "COC ROI",
    width: 80,
    valueFormatter: (params) => fractionToPercent(params.value),
  },
  {
    field: "adjCocRoi",
    headerName: "Adjusted COC ROI",
    width: 80,
    valueFormatter: (params) => fractionToPercent(params.value),
  },
  {
    field: "valueEstimate",
    headerName: "Current Value Estimate",
    width: 120,
    valueGetter: (params) => params.row.valuationMetrics?.valueEstimate,
    valueFormatter: (params) => numberToUSD(params.value),
  },
  {
    field: "totalApprecUSD",
    headerName: "Total Appreciation",
    width: 120,
    valueGetter: (params) => params.row.valuationMetrics?.totalApprecUSD,
    valueFormatter: (params) => numberToUSD(params.value),
  },
  {
    field: "reportPeriodApprecUSD",
    headerName: "Estimated Report Period Appreciation",
    width: 120,
    valueGetter: (params) => params.row.valuationMetrics?.reportPeriodApprecUSD,
    valueFormatter: (params) => numberToUSD(params.value),
  },
  {
    field: "reportPeriodApprecPerc",
    headerName: "Estimated Report Period Appreciation (%)",
    width: 120,
    valueGetter: (params) =>
      params.row.valuationMetrics?.reportPeriodApprecPerc,
    valueFormatter: (params) => fractionToPercent(params.value),
  },
  {
    field: "combinedRoiUSD",
    headerName: "Combined ROI",
    width: 120,
    valueGetter: (params) => params.row.valuationMetrics?.combinedRoiUSD,
    valueFormatter: (params) => numberToUSD(params.value),
  },
  {
    field: "combinedRoiPerc",
    headerName: "Combined ROI (%)",
    width: 120,
    valueGetter: (params) => params.row.valuationMetrics?.combinedRoiPerc,
    valueFormatter: (params) => fractionToPercent(params.value),
  },
];

export default function DataTable({ data }) {
  console.log("DataTable: data: ", data);

  data.forEach((datum, i) => {
    datum.id = i;
  });

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        /* checkboxSelection */
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important",
          },
        }}
      />
    </div>
  );
}
