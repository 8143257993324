// ThemeComponent.tsx
import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  ReactNode,
} from "react";
import { createTheme, ThemeProvider, CssBaseline, Theme } from "@mui/material";
import darkTheme from "../themes/darkTheme";
import lightTheme from "../themes/lightTheme";

interface ThemeComponentProps {
  children: ReactNode;
}

interface ThemeContextType {
  darkMode: boolean;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextType>({
  darkMode: false,
  toggleTheme: () => {},
});

export const ThemeComponent: React.FC<ThemeComponentProps> = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(false);

  const theme: Theme = useMemo(
    () => (darkMode ? darkTheme : lightTheme), // Use darkTheme if darkMode is true
    [darkMode],
  );

  // Optional: Function to toggle the theme
  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleTheme }}>
      <ThemeProvider theme={theme} key="{darkMode ? 'dark' : 'light'">
        <CssBaseline />
        {/* Optional: Include a theme toggle button or switch here */}
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
