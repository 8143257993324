import { EstimateSourceStatus, IEstimate } from "@farlosoftware/common-types";
import { formatDateStrToMDY, convertDateToISOString } from "./dateUtils";
import { formatEstimate } from "./formatting";

export function iEstimateToCSVString(estimate: IEstimate) {
  const lastUpdated = formatDateStrToMDY(
    convertDateToISOString(estimate.lastUpdated),
  );

  const value = formatEstimate(estimate.value ?? 0);
  let str = `${lastUpdated}; ${value};`;

  // sort sources alphabetically by source type (realtor, redfin, zillow)
  const sorted = estimate.sources.sort((a, b) =>
    a.sourceType.localeCompare(b.sourceType),
  );

  let dates: string[] = [];
  sorted.forEach((src, i) => {
    if (src.status === EstimateSourceStatus.Success) {
      str += formatEstimate(src.value ?? 0) + ";";
      dates[i] = formatDateStrToMDY(lastUpdated);
    } else {
      str += EstimateSourceStatus + ";";
      dates[i] = "N/A";
    }
  });

  dates.forEach((date) => {
    str += date + ";";
  });

  str += formatDateStrToMDY(convertDateToISOString(estimate.validStart)) + ";";
  str += formatDateStrToMDY(convertDateToISOString(estimate.validEnd));
  str += "\n";
  return str;
}
